import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const MultiSelectPicker = ({
  className = '',
  options = [],
  label = '',
  handleChangeMultiSelect,
  value = [],
  disabled = false,
}) => {
  const matchedValue = options.filter((option) =>
    value.some((selected) => selected.value === option.value)
  );

  return (
    <div className={className}>
      <Autocomplete
        value={matchedValue}
        multiple
        limitTags={2}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label={label} placeholder={label} />}
        sx={{ width: '100%' }}
        onChange={(event, value) => handleChangeMultiSelect(value)}
        disabled={disabled}
      />
    </div>
  );
};

export default MultiSelectPicker;
