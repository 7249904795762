import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';

import FullPageLoader from '../../pages/fullpage-loader/index';

import { GET_USER_CLUSTER_DATA } from '../../utils/url-helpers';
import { getRequest } from '../../utils/http-helper';
import { getUserId } from '../../utils/localStorage';

import info_icon from '../../assets/images/Info_circle.svg';

import './cluster-management.css';
import EditModal from '../view-edit-cluster';
import { getClusterDetails } from '../../services/cluster-service';
import { FIELD_OFFICER, MANAGER } from '../../utils/constants';

const headCells = [
  {
    id: 'srno.',
    numeric: true,
    disablePadding: false,
    label: 'Sr No.',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name of Cluster',
  },
  {
    id: 'manager',
    numeric: true,
    disablePadding: false,
    label: 'Managers',
  },
  {
    id: 'fieldOfficers',
    numeric: true,
    disablePadding: false,
    label: 'Field Officers',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f1f1f1',
    color: '#262261',
    fontSize: '18px',
    padding: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '10px',
  },
}));

function ClusterTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead className="cluster-table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            width={headCell.id === 'srno.' ? '8%' : '23%'}
            align={headCell.numeric || headCell.id === 'action' ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ClusterTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
    maxWidth: 250,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '12',
    border: '1px solid #dadde9',
    position: 'absolute',
  },
}));

export default function ClusterTable({ searchText }) {
  let navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loaderShow, setLoaderShow] = React.useState(false);
  const [allClusterData, setAllClusterData] = React.useState([]);
  const [clusterWiseData, setClusterWiseData] = useState([]);
  const [modalOpen, setModalOpen] = React.useState({
    staus: false,
    data: '',
  });

  const [fieldofficer, setFieldOfficers] = useState([]);
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchText !== '') {
      const options = {
        keys: ['name'],
      };
      const fuse = new Fuse(allClusterData, options);
      const result = fuse.search(searchText);
      setClusterWiseData(result.map((item) => item.item));
      setClusterWiseData(result.map((item) => item.item));
    } else {
      setClusterWiseData(allClusterData);
      setClusterWiseData(allClusterData);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fetchData = async () => {
    try {
      const { data, status } = await getClusterDetails();
      if (status === 200) {
        setManagers(
          data.data.managers.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
        setFieldOfficers(
          data.data.field_officers.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sendGetUserClusterData = async (user) => {
    try {
      return await getRequest({
        url: GET_USER_CLUSTER_DATA.replace('user_id', user),
        authHeader: true,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const getUserClusterData = async (user) => {
    setLoaderShow(true);
    const { data, status } = await sendGetUserClusterData(user);
    if (status === 200) {
      setLoaderShow(false);
      setClusterWiseData(data.data.clusterwise_users);
      setAllClusterData(data?.data?.clusterwise_users);
    } else if (status === 401) {
      toast.error(data.message);
      navigate('/login');
    } else {
      setLoaderShow(false);
      toast.error(data.message);
    }
  };

  const onClusterEditDetails = (userId) => {
    const userData = clusterWiseData.find((item) => item.id === userId);
    const manager = userData?.users.filter((item) => item.user_role === MANAGER);
    const fieldOfficer = userData?.users.filter((item) => item.user_role === FIELD_OFFICER);
    setModalOpen({
      staus: true,
      data: userData,
      fieldOfficer: fieldOfficer,
      manager: manager,
    });
  };

  useEffect(() => {
    getUserClusterData(getUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserClusterData(getUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const getTableData = (data) => {
    return searchText === ''
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data.slice(0, 20);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer className="cluster-table">
            <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
              <ClusterTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={clusterWiseData?.length}
              />
              <TableBody>
                {getTableData(clusterWiseData).map((row, index) => {
                  const managerCount =
                    row.users?.filter((item) => item.user_role === MANAGER).length || 0;
                  const fieldOfficerCount =
                    row.users?.filter((item) => item.user_role === FIELD_OFFICER).length || 0;

                  return (
                    <TableRow key={row.id} tabIndex={-1}>
                      <StyledTableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="center">
                        {managerCount > 0 ? managerCount : '-'}
                        {managerCount > 0 && (
                          <HtmlTooltip
                            title={
                              <div className="cluster-field-officers-list">
                                {row.users?.map((user) =>
                                  user.user_role === MANAGER ? (
                                    <span
                                      key={user.user_name}
                                      className="cluster-field-officer-names">
                                      {user.user_name}
                                    </span>
                                  ) : null
                                )}
                              </div>
                            }>
                            <img
                              src={info_icon}
                              alt="info-icon"
                              style={{ paddingBottom: '2.5px' }}
                            />
                          </HtmlTooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fieldOfficerCount > 0 ? fieldOfficerCount : '-'}
                        {fieldOfficerCount > 0 && (
                          <HtmlTooltip
                            title={
                              <div className="cluster-field-officers-list">
                                {row.users?.map((user) =>
                                  user.user_role === FIELD_OFFICER ? (
                                    <span
                                      key={user.user_name}
                                      className="cluster-field-officer-names">
                                      {user.user_name}
                                    </span>
                                  ) : null
                                )}
                              </div>
                            }>
                            <img
                              src={info_icon}
                              alt="info-icon"
                              style={{ paddingBottom: '2.5px' }}
                            />
                          </HtmlTooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span
                          className="user-view-link"
                          onClick={() => onClusterEditDetails(row.id)}>
                          Edit
                        </span>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
                {clusterWiseData.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      No record available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {searchText === '' ? (
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={clusterWiseData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ backgroundColor: '#f1f1f1' }}
              labelDisplayedRows={(page) =>
                `Showing ${page.from}-${page.to === -1 ? page.count : page.to} of  ${page.count}`
              }
            />
          ) : null}
        </Paper>
        {modalOpen.staus && (
          <EditModal
            show={modalOpen.staus}
            data={modalOpen.data}
            assingedFielOfficer={modalOpen.fieldOfficer}
            assingedManager={modalOpen.manager}
            fieldofficerOption={fieldofficer}
            managers={managers}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onHide={() => {
              setModalOpen({
                status: false,
                data: '',
              });
            }}
          />
        )}
      </Box>

      <FullPageLoader loaderShow={loaderShow} />
    </>
  );
}
