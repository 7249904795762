import React from 'react';
import { SettingsHeader, SettingsListItem } from '../settings-components';
import './layers-settings.css';

const AVAILABLE_LAYERS = [
  {
    id: 0,
    title: 'My Markings',
    description:
      'Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.',
  },
  {
    id: 1,
    title: 'All India Subdistricts',
    description:
      'Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.',
  },
  {
    id: 2,
    title: 'All India Villages',
    description:
      'Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.',
  },
  // {
  //     id: 3,
  //     title: 'My Tasks',
  //     description: 'Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.'
  // },
  // {
  //     id: 4,
  //     title: 'Land Revenue Boundaries',
  //     description: 'Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.'
  // },
  // {
  //     id: 5,
  //     title: 'Satellite Boundaries',
  //     description: 'Enable and Disable Tracking Mode to allow mobile app users to mark boundary around them.'
  // },
];

const LayersSettings = () => (
  <>
    <SettingsHeader title="Available Layers" />

    <section className="layers-settings-section">
      {AVAILABLE_LAYERS.map(({ id, title, description }) => (
        <SettingsListItem key={id} title={title} description={description} />
      ))}
    </section>
  </>
);

export default LayersSettings;
