import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { getRequest } from '../../utils/http-helper';
import { GET_FORMS } from '../../utils/url-helpers';
import { toast } from 'react-toastify';
import TableContainer from '@mui/material/TableContainer';
import Fuse from 'fuse.js';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '../../assets/images/Eye_black.svg';
import SortingArrow from '../../assets/images/icons/Sort.svg';
import './forms.css';
import { ViewEditUser } from '../index';
import FormTemplateModal from '../../pages/form-template-modal/index';

export default function UserTable({ search }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('form_name');
  const [page, setPage] = useState(0);
  const [forms, setForms] = useState([]);
  const [formPopupData, setFormPopupData] = useState();
  const [showFormTemplateModal, setShowFormTemplateModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nameSortOrder, setNameSortOrder] = useState('asc');
  const [originalOrder, setOriginalOrder] = useState('asc');
  const [originalOrderBy, setOriginalOrderBy] = useState('form_name');
  const [viewEditData, setViewEditData] = useState({
    viewEditUserModalShow: false,
    viewEditUserData: {
      id: '',
      name: '',
      email: '',
      phone: '',
      role: '',
      selectedClusterData: [],
    },
  });

  /**
   * ^ Function to send a GET request to retrieve data for the user.
   * @returns {Promise<Object>} Data for the user
   */
  const sendGetDataForUser = async () => {
    try {
      return await getRequest({
        url: GET_FORMS,
        authHeader: true,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  /**
   * Function to retrieve data for the user and set the forms state.
   */
  const getDataforUser = async () => {
    const { data, status } = await sendGetDataForUser();
    if (status === 200) {
      setForms(data.data);
    } else if (status === 401) {
      toast.error(data.msg);
    } else {
      toast.error(data.message);
    }
  };

  /**
   * ^ Function to handle the change in the current page.
   * @param {object} event - The event object
   * @param {number} newPage - The new page number
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * ^ Function to handle the change in the number of rows per page.
   * @param {object} event - The event object
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * ^ Function to handle the request for sorting data.
   * @param {object} event - The event object
   * @param {string} property - The property to be sorted
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    if (isAsc) {
      setOrder(originalOrder);
      setOrderBy(originalOrderBy);
      setNameSortOrder(originalOrder);
    } else {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      setNameSortOrder(isAsc ? 'desc' : 'asc');
    }
  };

  /**
   * Function to handle the hiding of the modal.
   */
  const onModalHide = () => {
    setViewEditData((prev) => {
      return {
        ...prev,
        viewEditUserModalShow: false,
        isFormDisabled: true,
      };
    });
  };

  /**
   * ^ Comparator function for sorting data in descending order.
   * @param {*} a - First comparison object
   * @param {*} b - Second comparison object
   * @param {string} orderBy - The property to be ordered by
   * @returns {number} Comparison result
   */
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  /**
   * ^ Function to get the comparator based on the order and the orderBy property.
   * @param {string} order - The current order
   * @param {string} orderBy - The current orderBy property
   * @returns {Function} Comparator function
   */
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name of the form',
      options: {
        customHeadRender: ({ index, ...column }) => {
          return <TableCell key={index}>{column.label}</TableCell>;
        },
      },
    },
    {
      id: 'filled',
      numeric: true,
      disablePadding: false,
      label: 'Total Filled',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f1f1f1',
      color: '#262261',
      fontSize: '16px',
      paddingleft: '20px',
      fontWeight: '600',
    },

    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      padding: '10px',
      borderBottom: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#fff',
    },
  }));

  const UserTableHead = (props) => {
    const { order, orderBy } = props;

    return (
      <TableHead className="form-table-header">
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              fontWeight={600}
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              {headCell.id === 'name' ? (
                <>
                  {headCell.label}
                  <img
                    src={SortingArrow}
                    alt=""
                    style={{
                      paddingLeft: '10px',
                      cursor: 'pointer',
                      transform: nameSortOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
                    }}
                    onClick={() => handleRequestSort(null, 'name')}
                  />
                </>
              ) : (
                headCell.label
              )}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  UserTableHead.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  useEffect(() => {
    if (search !== '') {
      getSearchData(search);
    } else {
      setForms([]);
      getDataforUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getSearchData = (searchText) => {
    const options = {
      keys: ['form_name'],
    };
    const fuse = new Fuse(forms, options);
    const result = fuse.search(searchText);
    setForms(result.map((item) => item.item));
  };

  useEffect(() => {
    getDataforUser();
    setOriginalOrder('asc');
    setOriginalOrderBy('form_name');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%' }}>
          <TableContainer className="form-table">
            <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
              <UserTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                nameSortOrder={nameSortOrder}
                rowCount={forms.length}
              />
              <TableBody>
                {stableSort(forms, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <>
                        <StyledTableRow tabIndex={-1}>
                          <StyledTableCell align="left" style={{ paddingLeft: '20px' }}>
                            {row.form_name}
                          </StyledTableCell>
                          <StyledTableCell className="table-row" align="left">
                            {row.count}
                          </StyledTableCell>
                          <StyledTableCell className="table-action" align="left">
                            <span className="form-view-link">
                              {/* <img src={IconButton} /> */}
                              <img
                                src={VisibilityIcon}
                                onClick={() => {
                                  setFormPopupData(row);
                                  setShowFormTemplateModal(true);
                                }}
                                alt=""
                              />
                            </span>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    );
                  })}
                {forms.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      No record available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={forms.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{
              backgroundColor: '#F1F1F1',
            }}
            labelDisplayedRows={(page) =>
              `Showing ${page.from}-${page.to === -1 ? page.count : page.to} of  ${page.count}`
            }
          />
        </Paper>
      </Box>
      <ViewEditUser
        show={viewEditData.viewEditUserModalShow}
        data={viewEditData}
        onHide={() => onModalHide()}
      />
      <FormTemplateModal
        onHide={() => setShowFormTemplateModal(false)}
        formDetail={formPopupData}
        showFormModal={showFormTemplateModal}
      />
    </>
  );
}
