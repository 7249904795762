import { useEffect, useState } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
// Redux
import { useDispatch } from 'react-redux';
import { dataExplorerAction } from '../../store/dataExplorer/data-explorer-slice';
import './sidebar.css';
import { getUserGroupId } from '../../utils/localStorage';
import * as constants from '../../utils/constants';

import Navbar from '../navbar';
import DownloadFilters from '../download-filters';
import DataExplorerStatusList from '../data-explorer-status-list';
import AdminHome from '../../containers/admin-home';
import MapComponent from '../../containers/map-component';
import UserClusterManagementComponent from '../../containers/user-cluster-management';
import Profile from '../profile-popover';
import Settings from '../../containers/settings';

import CreateTask from '../../pages/create-task';
import Forms from '../forms';
import sparta_logo from '../../assets/images/logo/sparta_maps_logo_small.svg';
import user_white from '../../assets/images/icons/people icon.svg';
import user_selected from '../../assets/images/icons/people-blue.svg';
import home_white from '../../assets/images/icons/home.svg';
import home_selected from '../../assets/images/icons/home-blue.svg';
import forms_white from '../../assets/images/icons/forms.svg';
import form_selected from '../../assets/images/icons/forms-blue.svg';
import folder_white from '../../assets/images/icons/data-explorer.svg';
import folder_selected from '../../assets/images/icons/data-explorer-blue.svg';
import tasking_white from '../../assets/images/icons/tasking.svg';
import tasking_Blue from '../../assets/images/icons/tasking-blue.svg';
import { updateGtPopintStatus } from '../../services/data-explorer-services';
import { toast } from 'react-toastify';

const Sidebar = () => {
  const dispatch = useDispatch();
  const [isTaskOpen, setTaskOpen] = useState(false);
  const [isHomeSelected, setIsHomeSelected] = useState(true);
  const [isFolderOpen, setIsFolderOpen] = useState(false);
  const [isUserManagementSelected, setIsUserManagementSelected] = useState(false);
  const [isFormView, setIsFormView] = useState(false);
  const [isTaskingView, setIsTaskingView] = useState(false);
  const [homeImage, setHomeImage] = useState(home_selected);
  const [folderImage, setFolderImage] = useState(folder_white);
  const [formImage, setFormImage] = useState(forms_white);
  const [taskingImage, setTaskingImage] = useState(tasking_white);
  const [userManagementImage, setUserManagementImage] = useState(user_white);
  const [showClusterTable, setShowClusterTable] = useState(false);
  const [showUserTable, setShowUserTable] = useState(true);
  const [mapObject, setMap] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [showAddUserPage, setShowAddUserPage] = useState(false);
  const [search, setSearch] = useState('');
  const [gtPointList, setGtPointList] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState({
    reasonRejectionPop: false,
    reasonRejectionStatus: false,
    confirmationType: '',
  });
  const [modalPopUpStatus, setModalPopUpStatus] = useState('');
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [selectedFarmId, setSelectedFarmId] = useState([]);
  const [selectedDataForApproval, setSelectedDataForApproval] = useState(null);

  const setSettingTabIndex = () => {
    setHomeImage(home_white);
    setTaskingImage(tasking_white);
    setFolderImage(folder_white);
    setFormImage(forms_white);
    setUserManagementImage(user_white);
    if (getUserGroupId(constants.USERGROUP_ID) === '1') {
      setTabIndex(5);
    } else {
      setTabIndex(4);
    }
  };

  const onTabSelect = (index) => setTabIndex(index);

  useEffect(() => {
    if (tabIndex === 2) {
      setTaskOpen(false);
    }
  }, [tabIndex]);

  const approveReject = (approvestatus) => {
    const commonStatusProps = {
      confirmationType: approvestatus,
      reasonRejectionStatus: true,
    };

    setOpenConfirmation({
      ...openConfirmation,
      ...commonStatusProps,
      ...(approvestatus === 'Approve' && { reasonRejectionStatus: true }),
      ...(approvestatus === 'Rejected' && { reasonRejectionPop: true }),
    });

    const payload = {
      status: approvestatus,
      farmdata_ids: selectedFarmId,
    };

    if (approvestatus === 'Approve') {
      updatedGtPointList(payload);
    }
  };

  const rejectedReason = (reasonId, approvestatus) => {
    setOpenConfirmation({
      ...openConfirmation,
      reasonRejectionPop: false,
      reasonRejectionStatus: true,
    });

    const payload = {
      status: approvestatus,
      farmdata_ids: selectedFarmId,
      rejection_id: reasonId,
    };

    updatedGtPointList(payload);
  };

  const updatedGtPointList = async (payload) => {
    const { data, status, error } = await updateGtPopintStatus(payload);
    if (status === 200) {
      setTimeout(() => {
        setOpenConfirmation({
          ...openConfirmation,
          reasonRejectionPop: true,
          confirmationType: '',
        });
      }, 3000);
    }
    if (status === 400) {
      setTimeout(() => {
        setOpenConfirmation({
          ...openConfirmation,
          reasonRejectionPop: true,
          confirmationType: '',
        });
      }, 3000);
      toast.error(error.response.data.data);
    }
    if (error) {
      toast.error(data);
    }
  };
  return (
    <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
      <div className="sidebar-tabs">
        <div className="sidebar-tablist-container">
          <div className="sidebar">
            <TabList>
              <div className="side-bar-image-wrapper">
                <img src={sparta_logo} alt="sparta-logo" />
              </div>
              <div className="side-menu">
                <Tab>
                  <div className="sidebar_icon_menu">
                    <img
                      src={homeImage}
                      alt="home-logo"
                      className={isHomeSelected ? 'home-logo-selected' : ''}
                      onClick={() => {
                        setSearch('');
                        setGtPointList([]);
                        setIsHomeSelected(true);
                        setIsFolderOpen(false);
                        setIsFormView(false);
                        setIsTaskingView(false);
                        setIsUserManagementSelected(false);
                        setHomeImage(home_selected);
                        setTaskingImage(tasking_white);
                        setFolderImage(folder_white);
                        setFormImage(forms_white);
                        setUserManagementImage(user_white);
                        setShowAddUserPage(false);
                      }}
                    />
                  </div>
                </Tab>
                <Tab>
                  <div className="sidebar_icon_menu">
                    <img
                      src={folderImage}
                      alt="folder-logo"
                      className={isFolderOpen ? 'folder-open' : ''}
                      onClick={() => {
                        dispatch(dataExplorerAction.doClearDataExplorer());
                        setSearch('');
                        setGtPointList([]);
                        setIsFolderOpen(true);
                        setIsHomeSelected(false);
                        setIsFormView(false);
                        setIsTaskingView(false);
                        setIsUserManagementSelected(false);
                        setTaskingImage(tasking_white);
                        setHomeImage(home_white);
                        setFolderImage(folder_selected);
                        setFormImage(forms_white);
                        setUserManagementImage(user_white);
                        setShowAddUserPage(false);
                      }}
                    />
                  </div>
                </Tab>

                {getUserGroupId(constants.USERGROUP_ID) === '1' ? (
                  <Tab>
                    <div className="sidebar_icon_menu">
                      <img
                        src={taskingImage}
                        alt="form-logo"
                        className={isTaskingView ? 'tasking-view' : ''}
                        onClick={() => {
                          setIsTaskingView(true);
                          setSearch('');
                          setGtPointList([]);
                          setIsFormView(false);
                          setTaskingImage(tasking_Blue);
                          setIsUserManagementSelected(false);
                          setIsHomeSelected(false);
                          setIsFolderOpen(false);
                          setFormImage(forms_white);
                          setHomeImage(home_white);
                          setFolderImage(folder_white);
                          setUserManagementImage(user_white);
                          setShowAddUserPage(false);
                        }}
                      />
                    </div>
                  </Tab>
                ) : null}

                {/* <Tab>
                  <div className="sidebar_icon_menu">
                    <img
                      src={formView}
                      alt="form-logo"
                      className={
                        isFormView
                          ? "form-view-logo-selected"
                          : ""
                      }
                      onClick={() => {
                        setIsFormView(true);
                        setIsUserManagementSelected(false);
                        setIsHomeSelected(false);
                        setIsFolderOpen(false);
                        setFormView(form_selected);
                        setHomeImage(home_white);
                        setFolderImage(folder_white);
                        setUserManagementImage(user_white);
                      }}
                    />
                  </div>
                </Tab> */}

                <Tab>
                  <div className="sidebar_icon_menu">
                    <img
                      src={formImage}
                      alt="form-logo"
                      className={isFormView ? 'form-view-selected' : ''}
                      onClick={() => {
                        setIsFormView(true);
                        setSearch('');
                        setGtPointList([]);
                        setIsTaskingView(false);
                        setIsUserManagementSelected(false);
                        setIsHomeSelected(false);
                        setTaskingImage(tasking_white);
                        setIsFolderOpen(false);
                        setHomeImage(home_white);
                        setFolderImage(folder_white);
                        setFormImage(form_selected);
                        setUserManagementImage(user_white);
                        setShowAddUserPage(false);
                      }}
                    />
                  </div>
                </Tab>

                {getUserGroupId(constants.USERGROUP_ID) === '1' ? (
                  <Tab>
                    <div className="sidebar_icon_menu">
                      <img
                        src={userManagementImage}
                        alt="usermanagement-logo"
                        className={isUserManagementSelected ? 'user-management-logo-selected' : ''}
                        onClick={() => {
                          setSearch('');
                          setGtPointList([]);
                          setIsUserManagementSelected(true);
                          setIsHomeSelected(false);
                          setIsFolderOpen(false);
                          setIsFormView(false);
                          setIsTaskingView(false);
                          setHomeImage(home_white);
                          setTaskingImage(tasking_white);
                          setFolderImage(folder_white);
                          setFormImage(forms_white);
                          setUserManagementImage(user_selected);
                          setShowAddUserPage(false);
                        }}
                      />
                    </div>
                  </Tab>
                ) : null}
              </div>
            </TabList>
            <div>
              <Profile setSettingTabIndex={setSettingTabIndex} />
            </div>
          </div>
        </div>

        <div className="sidebar-tabpanel-container">
          <TabPanel>
            <Navbar navbarTitle="HOME" isHomePage="true" />
            <div className="panel-content home-sections d-flex data-explorer-container">
              <AdminHome mapObject={mapObject} setTabIndex={setTabIndex} />
              <MapComponent mapObject={mapObject} setMap={setMap} />
            </div>
          </TabPanel>
          <TabPanel>
            <Navbar navbarTitle="DATA EXPLORER" />
            <div className="panel-content d-flex data-explorer-container">
              <DownloadFilters
                gtPointList={gtPointList}
                setGtPointList={setGtPointList}
                mapObject={mapObject}
                openConfirmation={openConfirmation}
                setOpenConfirmation={setOpenConfirmation}
                modalPopUpStatus={modalPopUpStatus}
                setModalPopUpStatus={setModalPopUpStatus}
                approveReject={approveReject}
                rejectedReason={rejectedReason}
                setIsApproveModalOpen={setIsApproveModalOpen}
                setSelectedDataForApproval={setSelectedDataForApproval}
                setSelectedFarmId={setSelectedFarmId}
                isApproveModalOpen={isApproveModalOpen}
                selectedDataForApproval={selectedDataForApproval}
              />
              <div
                className={`data-explorer-map ${
                  ['1', '2'].includes(getUserGroupId(constants.USERGROUP_ID)) &&
                  gtPointList?.length !== 0
                    ? 'gtlist'
                    : 'emptygtlist'
                }`}>
                <MapComponent mapObject={mapObject} setMap={setMap} />
                {['1', '2'].includes(getUserGroupId(constants.USERGROUP_ID)) && (
                  <>
                    {gtPointList?.length !== 0 && (
                      <DataExplorerStatusList
                        mapObject={mapObject}
                        gtPointList={gtPointList}
                        openConfirmation={openConfirmation}
                        setOpenConfirmation={setOpenConfirmation}
                        modalPopUpStatus={modalPopUpStatus}
                        setModalPopUpStatus={setModalPopUpStatus}
                        approveReject={approveReject}
                        setSelectedStatusIds={setSelectedFarmId}
                        selectedStatusIds={selectedFarmId}
                        setIsApproveModalOpen={setIsApproveModalOpen}
                        setSelectedDataForApproval={setSelectedDataForApproval}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </TabPanel>
          {getUserGroupId(constants.USERGROUP_ID) === '1' ? (
            <TabPanel>
              <Navbar
                navbarTitle="TASKING"
                isTaskingView={!isTaskOpen}
                setTaskOpen={setTaskOpen}
                setSearch={setSearch}
                search={search}
              />
              <div className="panel-content tasking">
                <CreateTask isTaskOpen={isTaskOpen} setTaskOpen={setTaskOpen} search={search} />
              </div>
            </TabPanel>
          ) : null}
          <TabPanel>
            <Navbar navbarTitle="FORMS" isFormView="true" setSearch={setSearch} search={search} />
            <div className="panel-content form-container user-management-container">
              <Forms search={search} />
            </div>
          </TabPanel>
          <TabPanel>
            <Navbar
              showUserTable={showUserTable}
              showClusterTable={showClusterTable}
              setShowUserTable={setShowUserTable}
              setShowClusterTable={setShowClusterTable}
              navbarTitle="USER MANAGEMENT"
              isUserManagement="true"
              showAddUserPage={showAddUserPage}
              setShowAddUserPage={setShowAddUserPage}
              setSearch={setSearch}
              search={search}
            />
            <div className="panel-content user-management-container">
              <UserClusterManagementComponent
                showUserTable={showUserTable}
                showClusterTable={showClusterTable}
                showAddUserPage={showAddUserPage}
                setShowAddUserPage={setShowAddUserPage}
                search={search}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <Navbar navbarTitle="SETTINGS" />
            <div className="panel-content settings">
              <Settings />
            </div>
          </TabPanel>
        </div>
      </div>
    </Tabs>
  );
};

export default Sidebar;
