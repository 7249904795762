import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getRequest } from '../../utils/http-helper';
import { getUserId, getUserGroupId } from '../../utils/localStorage';
import { GET_HOME_PAGE_DATA, DAILY_DATA_URL } from '../../utils/url-helpers';
import * as constants from '../../utils/constants';
import FullPageLoader from '../../pages/fullpage-loader/index';
import Leaderboard from '../../pages/leaderboard';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Heatmap as HeatmapLayer } from 'ol/layer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import profile_icon from '../../assets/images/profile_icon.svg';
import location_icon from '../../assets/images/location_pin.svg';
import star from '../../assets/images/star.svg';

import './admin-home.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const calendarOptions = [
  { key: 0, value: 7, label: 'Last 7 days' },
  { key: 1, value: 14, label: 'Last 2 weeks' },
  { key: 2, value: 28, label: 'Last 4 weeks' },
  { key: 3, value: 42, label: 'Last 6 weeks' },
];
const AdminHome = ({ mapObject, setTabIndex }) => {
  const [loaderShow, setLoaderShow] = useState(false);
  const [weeklyList, setWeeklyList] = useState(calendarOptions[0]);
  const [daysLineChartData, setDaysLineChartData] = useState();

  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [homePageData, setHomePageData] = useState({
    field_officers: '',
    total_points: '',
    users_leaderboard: [],
    form_data: [],
  });
  const options = {
    spanGaps: true,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          // reverse: true,
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 15,
        },
        border: {
          dash: [5],
        },
        title: {
          display: true,
          text: 'Dates',
        },
        display: true,
        grid: {
          borderDash: [5],
          borderWidth: 1,
          drawBorder: false,
          drawTicks: false,
          dash: [5],
          color: '#E0E0E0',
        },
      },
      y: {
        max: daysLineChartData?.max_count,
        min: daysLineChartData?.min_count,
        title: {
          display: true,
          text: 'No. of Points Marked',
        },
        grid: {
          display: false,
        },
      },
    },
  };

  /**
   * ^ Sends a GET request for daily data
   * @param {*} calendarOptions - Options for the calendar
   * @returns - Response from the GET request
   */
  const sendGetDailyData = async (calendarOptions) => {
    try {
      return await getRequest({
        url: `${DAILY_DATA_URL}${calendarOptions}`,
        authHeader: true,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  /**
   * Fetches the daily data if mapObject exists
   */
  useEffect(() => {
    if (mapObject) {
      getDailyDaysData(weeklyList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapObject]);

  /**
   * ^ Handles the selection of the week and gets the corresponding data
   * @param {*} event - The event object
   */
  const onSelectWeek = (event) => {
    getDailyDaysData(event);
    setWeeklyList(event);
  };

  /**
   * ^ Fetches the daily days data
   * @param {*} weeklyList - List of the week
   */
  const getDailyDaysData = async (weeklyList) => {
    const { data, status } = await sendGetDailyData(weeklyList?.value);
    if (status === 200) {
      heatMapLayerFun(data?.data?.heat_data);
      setDaysLineChartData(data?.data?.graph_data);
    }
  };

  /**
   * ^ Handles the logic for the heat map layer
   * @param {*} heatData - Heat data to be displayed
   */
  const heatMapLayerFun = (heatData) => {
    mapObject.getLayers().forEach(function (layer) {
      if (layer && layer.get('name') === 'heatmaplayer') {
        mapObject.removeLayer(layer);
      }
    });

    let heatfeature = new VectorSource({
      features: new GeoJSON().readFeatures(heatData, {
        dataProjection: 'EPSG:4326',
        featureProjection: mapObject.getView().getProjection(),
      }),
    });
    heatMapLayer(heatfeature);
  };

  /**
   * ^ Handles the heat map layer creation
   * @param {*} heatfeature - Heat feature data
   */
  const heatMapLayer = (heatfeature) => {
    let blur = 20;
    let radius = 10;
    // const rainbow = [
    //     'rgba(127.5, 0.0, 255.0, 1.0)',
    //     'rgba(0.4999999999999982, 180.8667334394926, 235.43872205689976, 1.0)',
    //     'rgba(128.5, 254.99516197392535, 179.75601446106756, 1.0)',
    //     'rgba(255.0, 178.63847458628922, 96.49481776078913, 1.0)',
    //     'rgba(255.0, 3.122849337825751e-14, 1.5614246689128753e-14, 1.0)',
    // ]

    let heatmaplayer = new HeatmapLayer({
      title: 'HeatMap',
      source: heatfeature,
      blur: blur,
      radius: radius,
      // gradient: rainbow,
      weight: function (feature) {
        return 10;
      },
    });
    mapObject.addLayer(heatmaplayer);
    heatmaplayer.set('name', 'heatmaplayer');
  };

  const data = {
    labels: daysLineChartData?.data !== undefined ? Object.keys(daysLineChartData?.data) : '',
    datasets: [
      {
        data: daysLineChartData?.data !== undefined ? Object.values(daysLineChartData?.data) : '',
        borderColor: '#EB704B',
        backgroundColor: '#EB704B',
        borderWidth: [2],
        //borderDash: [1, 1],
      },
    ],
  };

  const UserLeaderBoardHeadCells = [
    {
      id: 'srno.',
      numeric: true,
      disablePadding: false,
      label: 'Rankings',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'count',
      numeric: true,
      disablePadding: false,
      label: 'Points',
    },
  ];

  const FormLeaderBoardHeadCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name of Form',
    },
    {
      id: 'fomrsFilled',
      numeric: true,
      disablePadding: false,
      label: 'Forms Filled',
    },
  ];

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#ffffff',
      border: 'none',
      color: '#878787',
      fontSize: '14px',
      padding: '5px',
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      padding: '5px',
    },
  }));

  const colourStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
      border: isDisabled ? '' : '1px solid #262261',
      outline: isDisabled ? '' : '1px solid #262261',

      ':hover': {
        ...styles[':hover'],
        backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
        border: isDisabled ? '' : '1px solid #262261',
      },
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: '#ffffff',
        color: '#606060',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: '#262261',
          color: '#ffffff',
        },
      };
    },
  };

  const UserLeaderBoardTableHead = (props) => {
    return (
      <TableHead className="user-leaderboard-table-header">
        <TableRow>
          {UserLeaderBoardHeadCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}>
              {headCell.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const FormLeaderBoardTableHead = (props) => {
    return (
      <TableHead className="form-leaderboard-table-header">
        <TableRow>
          {FormLeaderBoardHeadCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}>
              {headCell.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  useEffect(() => {
    getHomePageAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHomePageAnalytics = async () => {
    setLoaderShow(true);
    const { data, status, error } = await sendGetHomePageDataRequest();

    if (status === 200) {
      setHomePageData((prev) => ({
        ...prev,
        field_officers: data.data.field_officer,
        total_points: data.data.points_data,
        users_leaderboard: data.data.user_data,
        form_data: data.data.form_data,
      }));
      setLoaderShow(false);
    } else {
      setLoaderShow(false);
      toast.error(error.response?.data.message);
    }
  };

  const sendGetHomePageDataRequest = async () => {
    try {
      return await getRequest({
        url: GET_HOME_PAGE_DATA + getUserId(constants.USER_ID),
        authHeader: true,
      });
    } catch (error) {
      return error;
    }
  };

  const onHideLeaderboard = () => setShowLeaderboard(false);
  const leaderboardModal = () => setShowLeaderboard(true);
  return (
    <>
      <div className="home-page-analytics">
        <div className="home-analytics-container">
          <div className="analytics-card">
            <ul className="analytics-card-item col-8">
              <li className="analytics-item-1">Points marked in 7 days</li>
              {getUserGroupId(constants.USERGROUP_ID) !== '4' ? (
                <li className="analytics-item-2">
                  <span className="analytics-item-2-name">BY MY ORGANIZATION</span>
                </li>
              ) : null}

              <li className="analytics-item-3">{homePageData.total_points}</li>
            </ul>
            <div className="analytics-card-image col-4">
              <img src={location_icon} alt="location-icon" />
            </div>
          </div>
          {getUserGroupId(constants.USERGROUP_ID) === '1' ||
          getUserGroupId(constants.USERGROUP_ID) === '2' ? (
            <div className="analytics-card">
              <ul className="analytics-card-item col-8">
                <li className="analytics-item-1">Total Field Officers</li>
                <li className="analytics-item-2">
                  <span className="analytics-item-2-name">IN MY ORGANIZATION</span>
                </li>
                <li className="analytics-item-3">{homePageData.field_officers}</li>
              </ul>
              <div className="analytics-card-image col-4">
                <img src={profile_icon} alt="profile-icon" />
              </div>
            </div>
          ) : null}
        </div>
        <div className="analytics-points">
          <div className="analytics-title">
            <label>Points breakdown</label>
            <span className="analytics-weekly-select">
              <Select
                classNamePrefix="select"
                options={calendarOptions}
                styles={colourStyles}
                onChange={(event) => {
                  onSelectWeek(event);
                }}
                value={weeklyList}
              />
            </span>
          </div>
          <div className="analytics-point-chart">
            <Line className="point-line-chart" options={options} data={data} />
          </div>
        </div>
        <div className="home-page-leaderboard-container d-flex">
          {getUserGroupId(constants.USERGROUP_ID) === '1' ||
          getUserGroupId(constants.USERGROUP_ID) === '2' ? (
            <div className="analytics-card weekly-leaderboard">
              <div className="leaderboard-title">
                <span>Weekly leaderboard</span>
                <label className="view-users-link" onClick={() => leaderboardModal()}>
                  View all users
                </label>
              </div>
              <div className="leader-board-table" style={{ display: 'flex' }}>
                {homePageData.users_leaderboard?.length === 0 && <span>No Data Available</span>}
                {homePageData.users_leaderboard?.length !== 0 && (
                  <TableContainer className="user-leaderboard-table">
                    <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
                      <UserLeaderBoardTableHead />
                      <TableBody>
                        {homePageData.users_leaderboard?.map((row, index) => (
                          <TableRow key={row.user_name} tabIndex={-1}>
                            <StyledTableCell align="left">
                              <div style={{ display: 'inline-flex' }}>
                                {index < 3 && <img src={star} alt="star" />}
                                {index + 1}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ paddingLeft: '10px' }}>
                              {row.user_name}
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ paddingLeft: '20px' }}>
                              {row.count}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          ) : null}

          <div className="analytics-card forms-leaderboard">
            <div className="leaderboard-title">
              <span>Forms</span>
              <label
                className="view-forms-link"
                onClick={() => setTabIndex(getUserGroupId(constants.USERGROUP_ID) === '1' ? 3 : 2)}>
                View all forms
              </label>
            </div>
            <div className="leader-board-table" style={{ display: 'flex' }}>
              {homePageData.form_data?.length === 0 && <span>No Data Available</span>}
              {homePageData.form_data?.length !== 0 && (
                <TableContainer className="user-leaderboard-table">
                  <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
                    <FormLeaderBoardTableHead />
                    <TableBody>
                      {homePageData.form_data?.map((row, index) => {
                        return (
                          <TableRow
                            tabIndex={-1}
                            // key={row.name}
                          >
                            <StyledTableCell align="left">{row.form_name}</StyledTableCell>
                            <StyledTableCell align="center">{row.count}</StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </div>

      <Leaderboard showModal={showLeaderboard} onHide={onHideLeaderboard} />
      <FullPageLoader loaderShow={loaderShow} />
    </>
  );
};

export default AdminHome;
