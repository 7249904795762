import React from 'react';
import * as constants from '../../utils/constants';
import { getUsername, getUserId, getUserGroupId, getCompanyId } from '../../utils/localStorage';
import SearchIcon from '../../assets/images/Search.svg';

import './navbar.css';

export default function Navbar({
  showAddUserPage,
  setShowAddUserPage,
  navbarTitle,
  isUserManagement,
  isFormView,
  isTaskingView,
  setShowClusterTable,
  setShowUserTable,
  showUserTable,
  showClusterTable,
  isHomePage,
  setTaskOpen,
  setSearch,
  search,
}) {
  const userData = {
    username: getUsername(constants.USERNAME),
    userid: getUserId(constants.USER_ID),
    userGroupId: getUserGroupId(constants.USERGROUP_ID),
    companyId: getCompanyId(constants.COMPANY_ID),
  };

  const handleUserFilterClick = (e) => {
    const { name } = e.target;
    if (name === 'user') {
      setShowUserTable(true);
      setShowClusterTable(false);
    } else {
      setShowUserTable(false);
      setShowClusterTable(true);
    }
    setSearch('');
  };

  return (
    <div className="navbar-container">
      {isHomePage && (
        <div className="admin-header">
          <span>Hey, {userData.username}</span>
          <span className="user-type">{constants.USER_TYPE[userData.userGroupId]}</span>
        </div>
      )}
      {!isHomePage && <div className="admin-header">{navbarTitle}</div>}

      {(isFormView || isTaskingView || isUserManagement) && (
        <div className="request-search">
          <input
            type="text"
            name="search"
            className="search-input"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={SearchIcon} alt="Search icon" />
        </div>
      )}

      {isUserManagement && (
        <div className="user-filter-wrapper">
          {!showAddUserPage && (
            <>
              <button
                type="button"
                className="btn add-user-button"
                onClick={() => setShowAddUserPage(true)}>
                <span>Add User</span>
              </button>
              <div className="user-filter">
                <button
                  type="button"
                  name="user"
                  onClick={handleUserFilterClick}
                  className={`btn user-filter-btn ${showUserTable ? 'user-filter-btn-active' : ''}`}>
                  User wise
                </button>
                <button
                  type="button"
                  name="cluster"
                  onClick={handleUserFilterClick}
                  className={`btn user-filter-btn ${showClusterTable ? 'user-filter-btn-active' : ''}`}>
                  Cluster wise
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {isTaskingView && (
        <button
          type="button"
          onClick={() => setTaskOpen(true)}
          className="btn comonon-nav-btn create-task-button">
          <span>Create Task</span>
        </button>
      )}
    </div>
  );
}
