import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const FullPageLoader = ({ loaderShow }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loaderShow}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullPageLoader;
