import { useEffect, useState } from 'react';
import moment from 'moment';
import Fuse from 'fuse.js';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { getCreateTaskDataRequest } from '../../services/task-services';
import FullPageLoader from '../../pages/fullpage-loader/index';
import UploadFile from '../uploadFile/uploadfile';
import DataValidation from '../data-validation/datavalidation';

// CSS
import './index.css';

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: 'NAME OF TASK',
    numeric: false,
    disablePadding: false,
    label: 'NAME OF TASK',
  },
  {
    id: 'STATUS',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 'ASSIGNED TO',
    numeric: true,
    disablePadding: false,
    label: 'ASSIGNED TO',
  },
  {
    id: 'START DATE',
    numeric: true,
    disablePadding: false,
    label: 'START DATE',
  },
  {
    id: 'DUE DATE',
    numeric: true,
    disablePadding: false,
    label: 'DUE DATE',
  },
];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f1f1f1',
    color: '#707070',
    fontSize: '16px',
    fontWeight: '700',
    padding: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#707070',
    fontSize: '16px',
    fontWeight: '500',
    padding: '10px',
    textTransform: 'capitalize',
    border: 'none',
  },
}));

const CreateTaskTableHead = (props) => {
  const { order, orderBy } = props;

  return (
    <TableHead className="leaderboard-table-header">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              {headCell.label}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const CreateTask = ({ isTaskOpen, setTaskOpen, search }) => {
  const [loaderShow, setLoaderShow] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('point');
  const [page, setPage] = useState(0);
  const [createTaskData, setCreateTaskData] = useState([]);
  const [createTaskTotalData, setCreateTaskTotalData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [trigger, setTrigger] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page_no: 1,
  });
  const [geometry, setGeometry] = useState('');

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setPagination({ ...pagination, page_no: newPage + 1 });
  };

  const handleRowsPerPageChange = (e, event) => {
    setPagination({ ...pagination, page_size: e.target.value });
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    viewAllTaskDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (search !== '') {
      getSearchData(search);
    } else {
      setCreateTaskData(createTaskTotalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  /**
   * Fetches the data for the current page based on the pagination settings
   */
  useEffect(() => {
    if (createTaskData && createTaskData.length !== 0) {
      setCreateTaskData(
        createTaskTotalData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  /**
   * ^ Searches the data based on the provided search text
   * @param {*} searchText - The text to be searched
   */
  const getSearchData = (searchText) => {
    const options = {
      keys: ['task_name', 'status', 'clusters.name'],
    };
    const fuse = new Fuse(createTaskTotalData, options);

    const result = fuse.search(searchText);

    setCreateTaskData(result.map((item) => item.item));
  };

  /**
   * Fetches all the details of the tasks
   */
  const viewAllTaskDetail = async () => {
    setLoaderShow(true);
    const { data, error, status } = await getCreateTaskDataRequest();
    if (status === 200) {
      setCreateTaskTotalData(data?.data);
      setCreateTaskData(data?.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
      setTotalCount(data?.total);
      setLoaderShow(false);
    } else if (error) {
      setLoaderShow(false);
    }
  };

  /**
   * ^ Sets the open status of the task
   * @param {*} status - The status to be set
   */
  const getTaskOpenStatus = (status) => {
    setTaskOpen(status);
  };

  return (
    <>
      {isTaskOpen ? (
        <>
          {fileSelected ? (
            <DataValidation
              geometry={geometry}
              getTaskOpenStatus={getTaskOpenStatus}
              setFileSelected={setFileSelected}
              setTrigger={setTrigger}
              trigger={trigger}
            />
          ) : (
            <div className="file-wrapper">
              <UploadFile setFileSelected={setFileSelected} setGeometry={setGeometry} />
            </div>
          )}
        </>
      ) : (
        <>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%' }}>
              <TableContainer className="create-task-table-container">
                <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
                  <CreateTaskTableHead order={order} orderBy={orderBy} />
                  <TableBody>
                    {stableSort(createTaskData, getComparator(order, orderBy)).map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={index}>
                          <StyledTableCell align="left">{row?.task_name}</StyledTableCell>
                          <StyledTableCell
                            align="left"
                            className={row?.status === 'Marked' && 'status-green'}>
                            {row?.status}
                          </StyledTableCell>
                          <StyledTableCell align="center">{row?.clusters[0]?.name}</StyledTableCell>
                          <StyledTableCell align="center">
                            {moment(row?.start_date).format('DD MMM YYYY')}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {moment(row?.end_date).format('DD MMM YYYY')}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                    {createTaskData.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={12}>
                          No record available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
          {search === '' && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              style={{ backgroundColor: '#f1f1f1' }}
              labelDisplayedRows={(page) =>
                `Showing ${page.from}-${page.to === -1 ? page.count : page.to} of  ${page.count}`
              }
            />
          )}
        </>
      )}
      {loaderShow && <FullPageLoader loaderShow={loaderShow} />}
    </>
  );
};

export default CreateTask;
