import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import {
  getUserId,
  getCompanyId,
  getUsername,
  getUseremail,
  getUserGroupId,
  removeAllCookies,
} from '../../utils/localStorage';
import * as constants from '../../utils/constants';
import { sendLogoutRequest } from '../../services/auth-services';

import './profile-popover.css';

import setting_logo from '../../assets/images/settings.svg';
import logout_logo from '../../assets/images/logout.svg';
import profile_logo from '../../assets/images/user_icon.svg';

const Profile = ({ setSettingTabIndex }) => {
  const [showPopover, setShowPopover] = useState(false);

  const onToggle = (value) => setShowPopover(value);

  const navigate = useNavigate();
  let userData = {
    username: getUsername(constants.USERNAME),
    useremail: getUseremail(constants.USEREMAIL),
    userid: getUserId(constants.USER_ID),
    userGroupId: getUserGroupId(constants.USERGROUP_ID),
    companyId: getCompanyId(constants.COMPANY_ID),
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="profile-account-container">
          <div className="d-flex">
            <span className="profile-popup-username">{userData.username}</span>
            <span className="profile-popup-usertype">
              {constants.USER_TYPE[userData.userGroupId]}
            </span>
          </div>
          <div className="profile-popup-email-container">
            <span className="profile-popup-email">{userData.useremail}</span>
          </div>
          <div className="d-flex profile-popover-menu" onClick={() => onSettingClick()}>
            <img src={setting_logo} alt="settings-logo" />
            <span className="profile-popover-settings">Settings</span>
          </div>
          <div className="d-flex profile-popover-menu" onClick={() => logout()}>
            <img src={logout_logo} alt="logout-logo" />
            <span className="profile-popover-logout">Logout</span>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const onSettingClick = () => {
    setShowPopover(false);
    setSettingTabIndex();
  };

  const logout = async () => {
    const { data, status } = await sendLogoutRequest();
    if (status === 200) {
      removeAllCookies();
      navigate('/login');
      toast.success('Successfully Logged Out');
    } else if (status === 401) {
      removeAllCookies();
      toast.error(data.msg);
      navigate('/login');
    } else {
      removeAllCookies();
      toast.error(data.message);
    }
  };

  return (
    <OverlayTrigger
      show={showPopover}
      onToggle={onToggle}
      trigger="click"
      rootClose
      placement="right"
      overlay={popover}>
      <img className="profile-account-logo" src={profile_logo} alt="profile-logo" />
    </OverlayTrigger>
  );
};

export default Profile;
