import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';

import './view-edit-user.css';
import MultiSelect from '../multi-select';
import edit_user from '../../assets/images/edit_user.svg';
import { updateUserCluster } from '../../services/cluster-service';

const ViewEditUser = (props) => {
  const roleOptions = [
    { value: 0, label: 'Select Role' },
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Manager' },
    { value: 3, label: 'Field Officers' },
  ];
  const [userDataInput, setUserDataInput] = useState({
    name: '',
    email: '',
    phone: '',
    role: {},
    selectedClusterData: [],
    fieldOfficers: [],
    allClusterData: [],
  });

  const [originalData, setOrginalData] = useState({
    role: {},
    selectedClusterData: [],
  });
  const [roleValid, setRoleValid] = useState(false);
  const [clusterValid, setClusterValid] = useState(false);

  /**
   * Function to enable the edit mode for the user data.
   */
  const enableEdit = () => {
    props.data.isFormDisabled = false;
    setUserDataInput((prev) => {
      return {
        ...prev,
        name: props.data.viewEditUserData.name,
        email: props.data.viewEditUserData.email,
        phone: props.data.viewEditUserData.phone,
        role: props.data.viewEditUserData.role,
        allClusterData: props.data.viewEditUserData.allClusterData,
        selectedClusterData: props.data.viewEditUserData.selectedClusterData,
      };
    });

    // Check if the original data has the necessary properties
    if (
      props.data.viewEditUserData &&
      props.data.viewEditUserData.role &&
      props.data.viewEditUserData.selectedClusterData
    ) {
      setOrginalData((prev) => {
        return {
          ...prev,
          role: props.data.viewEditUserData.role,
          selectedClusterData: props.data.viewEditUserData.selectedClusterData,
        };
      });
    }
  };

  /**
   * ^ Function to handle role change for the user.
   * @param {*} e - The event object.
   */
  const onRoleChange = (e) => {
    setUserDataInput((prev) => {
      return {
        ...prev,
        role: e,
      };
    });
    setRoleValid(e.value !== 0);
  };

  /**
   * & Function to handle cluster change for the user.
   * @param {*} e - The event object.
   */
  const onClusterChange = (e) => {
    setUserDataInput((prev) => {
      return {
        ...prev,
        selectedClusterData: e,
      };
    });
    props.setViewEditData((prev) => {
      return {
        ...prev,
        viewEditUserData: {
          selectedClusterData: e,
        },
      };
    });
    setClusterValid(e.length > 0);
  };

  /**
   * Function to handle form submission, updating the user data based on the input provided.
   */
  const onSubmit = async () => {
    props.onHide();
    if (!userDataInput?.role?.value || userDataInput.role.value === 0) {
      toast.error('Please Select a Role');
    } else if (userDataInput.selectedClusterData.length === 0) {
      toast.error('Please Select At-least one Cluster');
    } else {
      let payloadData = {
        role_id: '',
        user_id: '',
        cluster: {
          toBeAdded: [],
          toBeDeleted: [],
        },
      };
      if (originalData.role.value !== userDataInput.role.value) {
        payloadData.role_id = userDataInput.role.value;
      }

      const addedCluster = userDataInput.selectedClusterData.filter(
        (item) =>
          !originalData.selectedClusterData.some(
            (itemToBeRemoved) => itemToBeRemoved.value === item.value
          )
      );
      const removedCluster = originalData.selectedClusterData.filter(
        (item) =>
          !userDataInput.selectedClusterData.some(
            (itemToBeRemoved) => itemToBeRemoved.value === item.value
          )
      );

      if (payloadData.role_id !== '' || addedCluster.length !== 0 || removedCluster.length !== 0) {
        payloadData.user_id = props.data.selectedUser;
        payloadData.cluster.toBeAdded = addedCluster.map((element) => element.value);
        payloadData.cluster.toBeDeleted = removedCluster.map((element) => element.value);
      }
      const { status } = await updateUserCluster(payloadData);
      if (status === 200) {
        props.callUsermanagement();
        toast.success('Succesfully Updated');
      }
    }
    setClusterValid(false);
    setRoleValid(false);
  };

  /**
   * Function to close the modal and reset any validation errors.
   */
  const closeModal = () => {
    setClusterValid(false);
    setRoleValid(false);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="view-edit-user-modal-parent">
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data.isFormDisabled ? 'View Details' : 'Edit Details'}
        </Modal.Title>
        {props.data.isFormDisabled && (
          <div className="edit-pencil-icon mx-2">
            <img src={edit_user} alt="edit-user-button" onClick={() => enableEdit()} />
          </div>
        )}
      </Modal.Header>
      <Modal.Body className="view-edit-user-modal-body">
        <Form>
          <Form.Group
            className="view-edit-user-form-group view-edit-user-name d-flex flex-row my-2"
            key="name">
            <Form.Label className="view-edit-user-form-label">NAME OF THE USER</Form.Label>
            <Form.Control
              className="view-edit-user-input-name input-form-disabled"
              name="name"
              type="text"
              placeholder="Enter your name"
              value={props.data.viewEditUserData.name}
              disabled
              onChange={(name) =>
                setUserDataInput((prev) => {
                  return {
                    ...prev,
                    name: name.value,
                  };
                })
              }
            />
          </Form.Group>
          <Form.Group
            className="view-edit-user-form-group view-edit-user-email d-flex flex-row my-2"
            key="email">
            <Form.Label className="view-edit-user-form-label">EMAIL</Form.Label>
            <Form.Control
              className="view-edit-user-input-email input-form-disabled"
              name="email"
              type="text"
              placeholder="Enter your email"
              value={props.data.viewEditUserData.email}
              disabled
              onChange={(email) =>
                setUserDataInput((prev) => {
                  return {
                    ...prev,
                    email: email.value,
                  };
                })
              }
            />
          </Form.Group>
          <Form.Group
            className="view-edit-user-form-group view-edit-user-mobile d-flex flex-row my-2"
            key="mobile">
            <Form.Label className="view-edit-user-form-label">PHONE NUMBER</Form.Label>
            <Form.Control
              className="view-edit-user-input-mobile input-form-disabled"
              name="mobile"
              type="text"
              placeholder="Enter your phone number"
              value={props.data.viewEditUserData.phone}
              disabled
              onChange={(phone) =>
                setUserDataInput((prev) => {
                  return {
                    ...prev,
                    phone: phone.value,
                  };
                })
              }
            />
          </Form.Group>
          <Form.Group
            className="view-edit-user-form-group view-edit-user-role d-flex flex-row my-2"
            key="role">
            <Form.Label style={{ width: '95%' }} className="view-edit-user-form-label">
              ROLE
            </Form.Label>
            <Select
              defaultValue={
                props.data?.viewEditUserData?.role?.value === 2
                  ? roleOptions[2]
                  : props.data?.viewEditUserData?.role?.value === 1
                    ? roleOptions[1]
                    : roleOptions[3]
              }
              className={
                props.data.isFormDisabled
                  ? 'basic-single edit-user-role-form-select input-form-disabled'
                  : 'basic-single edit-user-role-form-select'
              }
              classNamePrefix="select"
              options={roleOptions}
              name="role"
              onChange={(e) => onRoleChange(e)}
              isDisabled={props.data.isFormDisabled}
            />
          </Form.Group>
          <Form.Group
            className="view-edit-user-form-group view-edit-user-cluster d-flex flex-row my-2"
            key="cluster">
            <Form.Label style={{ width: '95%' }} className="view-edit-user-form-label">
              CLUSTER
            </Form.Label>
            <div className="d-flex flex-column w-100">
              <Select
                className="edit-user-cluster-form-select mb-2"
                options={userDataInput.allClusterData}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  MultiSelect,
                }}
                value={
                  props.data.isFormDisabled ? '' : props.data.viewEditUserData.selectedClusterData
                }
                onChange={(e) => onClusterChange(e)}
                allowSelectAll={true}
                isDisabled={props.data.isFormDisabled}
              />
              <div className="view-edit-user-cluster-container">
                <ul>
                  {props.data.viewEditUserData.selectedClusterData.map((data) => (
                    <li key={data.label} className="d-flex justify-content-between">
                      {data.label}
                      <span>x</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {props.data.isFormDisabled ? (
          <>
            <Button
              className="edit-user-save"
              disabled
              onClick={() => {
                onSubmit();
              }}>
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal();
              }}
              className="edit-user-cancel">
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Button
              className="edit-user-save"
              disabled={!roleValid && !clusterValid}
              onClick={() => {
                onSubmit();
              }}>
              Save
            </Button>
            <Button
              onClick={() => {
                closeModal();
              }}
              className="edit-user-cancel">
              Cancel
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ViewEditUser;
