import { useEffect, useState } from 'react';
import './add-user.css';
import trash from '../../assets/images/trash.svg';
import dropdown from '../../assets/images/logo/dropdown.svg';
import Close from '../../assets/images/Close.svg';
import caution from '../../assets/images/caution.svg';
import back from '../../assets/images/ic_back.svg';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import Modal from 'react-modal';
import MultiSelect from '../multi-select';
import { isValidEmail, isValidPhoneNumber } from '../../utils/regex-helper';
import { getUserId } from '../../utils/localStorage';
import { toast } from 'react-toastify';
import { getRequest } from '../../utils/http-helper';
import * as constants from '../../utils/constants';
import { GET_CLUSTERS } from '../../utils/url-helpers';
import UploadCsvModal from '../upload-csv-modal';
import { sendClusterUserDetails } from '../../services/auth-services';
import CircularProgress from '@mui/material/CircularProgress';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '538px',
    height: '225px',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    borderRadius: '5px',
    textAlign: 'center',
    position: 'fixed',
    overflow: 'hidden',
  },
};

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
    background: '#F9F9F9',
    border: '1px solid #E0E0E0',
    borderRadius: '2px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    boxShadow: 'none',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
      background: '#F9F9F9',
      border: '1px solid #E0E0E0',
      borderRadius: '2px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: '#606060',
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#262261',
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
      },
    };
  },
};

const multiSelectColourStyles = {
  option: (styles, { isSelected, isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? '#6c67c9' : '#ffffff',
      color: isSelected ? '#212529' : '#606060',
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',

      ':hover': {
        ...styles[':hover'],
        backgroundColor: isSelected ? '#6c67c9' : '#262261',
        color: isSelected ? '#212529' : '#ffffff',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
      },
    };
  },
};

const AddNewUser = (props) => {
  const [selectedInputs, setSelectedInputs] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    cluster_id: [],
    row_id: '',
  });
  const [formError, setFormError] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    cluster_id: '',
    row_id: '',
  });

  const roleOptions = [
    { value: 2, label: 'Manager' },
    { value: 3, label: 'Field Officers' },
  ];

  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [clusterOptions, setClusterOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [indexToDelete, setIndexToDelete] = useState(null);
  const [isCSVModalOpen, setCSVModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    errors: [],
  });
  const [isErrorDisplay, setIsErrorDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const storedUserList = JSON.parse(localStorage.getItem('userList')) || [];
    setUserList(storedUserList);
  }, []);

  useEffect(() => {
    setUserList([...userList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg]);

  useEffect(() => {
    getClusterForUser(getUserId()).catch((error) => {});
    localStorage.setItem('userList', JSON.stringify(userList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  useEffect(() => {
    const hasInvalidData = userList.some((user) => {
      if (
        user?.name === '' ||
        !isValidEmail(user?.email) ||
        !isValidPhoneNumber(user?.phone) ||
        (user.role !== 'Manager' && user.role !== 'Field Officers') ||
        !user.cluster_id.every((cluster) =>
          clusterOptions.some((option) => option.label === cluster)
        )
      ) {
        setIsErrorDisplay(true);
        return true;
      } else {
        setIsErrorDisplay(false);
      }
      return false;
    });
    setIsErrorDisplay(hasInvalidData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList, clusterOptions]);

  const onClear = () => {
    setSelectedInputs({
      name: '',
      email: '',
      phone: '',
      role: '',
      cluster_id: [],
      row_id: '',
    });
  };
  const getClusterForUser = async (user) => {
    const { data, status, error } = await sendGetClusterForUser(user);
    if (status === 200) {
      updateClusterOption(data.data);
    } else if (status === 401) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.response?.data?.message);
    }
  };

  const sendGetClusterForUser = async (user) => {
    try {
      return await getRequest({
        url: GET_CLUSTERS.replace('user_id', user),
        authHeader: true,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCSVButtonClick = () => {
    setCSVModalOpen(true);
  };

  const getRoleId = (role) => {
    if (role === 'Admin') {
      return 1;
    }
    if (role === 'Manager') {
      return 2;
    }
    if (role === 'Field Officers') {
      return 3;
    }
    if (role === 'Public User') {
      return 4;
    }
  };

  const confirmDeleteUser = () => {
    setIsModalOpen(false);
    const updatedUserList = [...userList];
    updatedUserList.splice(indexToDelete, 1);
    setUserList(updatedUserList);

    toast.success('User deleted successfully!');
    const updatedErrorMsg = [...errorMsg.errors];
    const errorIndex = updatedErrorMsg.findIndex((item) => item.row === indexToDelete);

    if (errorIndex !== -1) {
      updatedErrorMsg.splice(errorIndex, 1);
      setErrorMsg({ errors: updatedErrorMsg });
    }
  };

  const updateClusterOption = (data) => {
    let clusterData = [];
    data.forEach((cluster) => {
      clusterData.push({
        value: cluster.cluster_id,
        label: cluster.cluster_name,
      });
    });
    setClusterOptions(clusterData);
  };

  const onRoleChange = (e) => {
    setSelectedInputs({ ...selectedInputs, role: e.label });
    setFormError({ ...formError, role: '' });
  };

  const handleDeleteUser = (index) => {
    setIndexToDelete(index);
    setIsModalOpen(true);
  };

  const onClusterChange = (e) => {
    const cluster_id = e.map((cluster) => cluster.label);
    const errorMessage = cluster_id.length !== 0 ? '' : 'Please Select Clusters';
    setFormError({ ...formError, cluster_id: errorMessage });
    setSelectedInputs({ ...selectedInputs, cluster_id });
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    switch (name) {
      case 'name':
        errorMessage = value === '' ? 'Please Enter a Username' : '';
        break;
      case 'email':
        errorMessage = !isValidEmail(value) ? 'Please Enter a Valid Email' : '';
        break;
      case 'phone':
        errorMessage = !isValidPhoneNumber(value) ? 'Please Enter a Valid Phone Number' : '';
        break;
      default:
        break;
    }

    setFormError((prev) => ({ ...prev, [name]: errorMessage }));
    setSelectedInputs((prev) => ({ ...prev, [name]: value }));
  };

  const sendClusterDetails = async (e) => {
    setIsLoading(true);
    const companyId = localStorage.getItem(constants.COMPANY_ID);
    const payload = {
      data: userList.map((user, index) => {
        return {
          name: user.name,
          phone: user.phone,
          email: user.email,
          cluster_id: user.cluster_id.map((item) => {
            const cluster = clusterOptions.find((cluster) => cluster.label === item);
            return cluster?.value;
          }),
          company_id: companyId,
          user_group_id: getRoleId(user.role),
          row_id: index,
        };
      }),
      bulk: true,
    };

    const { status, error } = await sendClusterUserDetails(payload);
    if (status === 200) {
      toast.success('User Created Successfully');
      setUserList([]);
      setIsLoading(false);
    } else if (error) {
      setErrorMsg(error?.response?.data);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }

    e.preventDefault();
  };

  useEffect(() => {
    const { name, email, phone, role, cluster_id } = selectedInputs;

    const allFieldsFilled =
      name.trim() !== '' &&
      email.trim() !== '' &&
      phone !== '' &&
      role !== '' &&
      cluster_id.length !== 0;

    setIsAddDisabled(!allFieldsFilled);
  }, [selectedInputs]);

  const onSubmit = () => {
    const { name, email, phone, role, cluster_id } = selectedInputs;

    let hasError = false;
    if (name === '') {
      setFormError({ ...formError, name: 'Please Enter a Username' });
      hasError = true;
    } else if (!isValidEmail(email)) {
      setFormError({ ...formError, email: 'Please Enter a Valid Email' });
      hasError = true;
    } else if (!isValidPhoneNumber(phone)) {
      setFormError({
        ...formError,
        phone: 'Please Enter a Valid Phone Number',
      });
      hasError = true;
    } else if (role !== 'Manager' && role !== 'Field Officers') {
      setFormError({ ...formError, role: 'Please enter a valid role' });
      hasError = true;
    } else if (cluster_id.length === 0) {
      setFormError({ ...formError, cluster_id: 'Please Select Clusters' });
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const newUser = {
      name,
      email,
      phone,
      role,
      cluster_id,
    };

    setUserList([...userList, newUser]);
    toast.success('User Added Successfully');

    setSelectedInputs({
      name: '',
      email: '',
      phone: '',
      role: '',
      cluster_id: [],
      row_id: '',
    });
    setFormError({
      name: '',
      email: '',
      phone: '',
      role: '',
      cluster_id: '',
      row_id: '',
    });

    const tableContainer = document.getElementById('user-table');
    if (tableContainer) {
      tableContainer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const deleteFunction = (userIndex, clusterName) => {
    const updatedUserList = [...userList];
    const user = updatedUserList[userIndex];

    const updatedClusterIds = user.cluster_id.filter((cluster) => cluster !== clusterName);
    user.cluster_id = updatedClusterIds;

    setUserList(updatedUserList);
  };

  const hasDuplicatePhoneNumber = (currentIndex) => {
    const currentPhoneNumber = userList[currentIndex]?.phone;
    if (currentPhoneNumber) {
      const duplicateIndex = userList.findIndex(
        (user, index) => index !== currentIndex && user.phone === currentPhoneNumber
      );
      return duplicateIndex !== -1;
    }
    return false;
  };

  return (
    <>
      <div className="add-new-user-container">
        <div className="add-new-user-header">
          <div className="left-content">
            <img
              src={back}
              alt="back"
              className="back-icon"
              onClick={() => props.setShowAddUserPage(false)}
            />
            <span className="title">Add User(s)</span>
          </div>

          <button className="btn upload-csv-button" onClick={() => handleCSVButtonClick()}>
            Upload CSV
          </button>
          {isCSVModalOpen && (
            <UploadCsvModal
              isCSVModalOpen={isCSVModalOpen}
              setCSVModalOpen={setCSVModalOpen}
              userList={userList}
              setUserList={setUserList}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          )}
        </div>

        <Form>
          <div className="add-user-form-container">
            <Form.Group className="add-user-form-group">
              <Form.Label className="add-user-form-label">
                NAME OF THE USER<span className="input-mandatory">*</span>
              </Form.Label>

              <Form.Control
                className={'add-user-form-input'}
                name="name"
                type="text"
                value={selectedInputs.name}
                placeholder="Enter your name"
                onChange={onInputChange}
              />

              {formError['name'] && <span className="add-user-form-errors">{formError.name}</span>}
            </Form.Group>

            <Form.Group className="add-user-form-group">
              <Form.Label className="add-user-form-label">
                EMAIL<span className="input-mandatory">*</span>
              </Form.Label>

              <Form.Control
                className={'add-user-form-input'}
                name="email"
                type="text"
                value={selectedInputs.email}
                placeholder="Enter email"
                onChange={onInputChange}
              />

              {formError['email'] && (
                <span className="add-user-form-errors">{formError.email}</span>
              )}
            </Form.Group>

            <Form.Group className="add-user-form-group">
              <Form.Label className="add-user-form-label">
                PHONE NUMBER<span className="input-mandatory">*</span>
              </Form.Label>

              <Form.Control
                className={'add-user-form-input'}
                name="phone"
                type="text"
                value={selectedInputs.phone}
                placeholder="Enter phone number"
                onChange={onInputChange}
              />

              {formError['phone'] && (
                <span className="add-user-form-errors">{formError.phone}</span>
              )}
            </Form.Group>

            <Form.Group className="add-user-form-group">
              <Form.Label className="add-user-form-label">
                ROLE<span className="input-mandatory">*</span>
              </Form.Label>

              <Select
                classNamePrefix="select"
                options={roleOptions}
                styles={colourStyles}
                onChange={onRoleChange}
                value={roleOptions.find((item) => item.label === selectedInputs.role) || null}
                placeholder={'Select Role'}
              />

              {formError['role'] && <span className="add-user-form-errors">{formError.role}</span>}
            </Form.Group>

            <Form.Group className="add-user-form-group">
              <Form.Label className="add-user-form-label">
                CLUSTER<span className="input-mandatory">*</span>
              </Form.Label>
              <div className="cluster-container">
                <Select
                  options={clusterOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{ MultiSelect }}
                  onChange={onClusterChange}
                  allowSelectAll={false}
                  value={
                    clusterOptions.filter((item) => {
                      return selectedInputs.cluster_id.includes(item.label);
                    }) || null
                  }
                  styles={{ ...colourStyles, ...multiSelectColourStyles }}
                  placeholder={'Select Cluster'}
                />

                {formError['cluster_id'] && (
                  <span className="add-user-form-errors">{formError.cluster_id}</span>
                )}
              </div>
            </Form.Group>
          </div>
        </Form>

        <div className={'add-user-form-button-container'}>
          <button className="clear-btn" onClick={onClear}>
            Clear All
          </button>
          <button className={'add-user-form-button'} disabled={isAddDisabled} onClick={onSubmit}>
            <span>Add</span>
          </button>
        </div>
      </div>

      <div className="table-content">
        <table className="add_user_table">
          <thead className="table-head">
            <div className="fix_header">
              <h2>Summary</h2>
              {errorMsg?.errors?.length !== 0 && <p>Delete all errors rows</p>}
            </div>
            <tr className="header_head">
              <th className="width-3"></th>
              <th className="width-16">NAME OF THE USER</th>
              <th className="width-16">EMAIL ADDRESS</th>
              <th className="width-16">PHONE NUMBER</th>
              <th className="width-16">ROLE</th>
              <th className="width-34" colSpan="5">
                CLUSTER(s)
              </th>
            </tr>
          </thead>
          <tbody className="add_user_table_body">
            {userList.map((user, index) => (
              <tr
                className={`add_user_tr ${errorMsg?.errors ? 'text_warning' : ''} ${
                  errorMsg?.errors?.some((item) => item.row === index) ? 'bg-color-tb' : ''
                }`}
                key={user.id || index}>
                <td className="basic width-3">
                  <span className="delete-icon" onClick={() => handleDeleteUser(index)}>
                    <img src={trash} alt="delete" />
                  </span>
                </td>
                <td className="basic width-16">
                  {user.name}
                  {/^\d+$/.test(user.name) && <span className="error_msg">Invalid Name</span>}
                </td>
                <td className="basic width-16">
                  {user.email}
                  {!isValidEmail(user.email) && <span className="error_msg">Invalid Email</span>}
                </td>
                <td className="basic width-16">
                  {user.phone}
                  {!isValidPhoneNumber(user.phone) && (
                    <span className="error_msg">Invalid PhoneNo</span>
                  )}
                  {hasDuplicatePhoneNumber(index) && (
                    <span className="error_msg">Duplicate Phone Number</span>
                  )}
                </td>
                <td className="basic role width-16">
                  {user.role}
                  {user.role !== 'Manager' && user.role !== 'Field Officers' ? (
                    <span className="error_msg">Invalid Role</span>
                  ) : (
                    <span className="green_dropdown">
                      <img src={dropdown} alt="dropDown" />
                    </span>
                  )}
                </td>
                <td colSpan="0" className="width-34">
                  <div className="cluster-order">
                    {user.cluster_id.length > 0 ? (
                      user.cluster_id
                        .slice()
                        .sort()
                        .map((cluster, clusterIndex) => (
                          <div key={clusterIndex} className="cluster_container">
                            <label>{cluster}</label>
                            <span
                              className="close_cluster"
                              onClick={() => deleteFunction(index, cluster)}>
                              <img src={Close} alt="Close" />
                            </span>
                          </div>
                        ))
                    ) : (
                      <span className="error_msg">Enter Valid Cluster</span>
                    )}
                  </div>
                  {/* <div className="hold-error">
                    {user.cluster_id.some(
                      (cluster) => !clusterOptions.some((option) => option.label === cluster)
                    ) && (
                      <span className="error_msg">
                        <strong>Cluster not found:</strong>
                      </span>
                    )}
                    {user.cluster_id
                      .filter(
                        (cluster) => !clusterOptions.some((option) => option.label === cluster)
                      )
                      .map((invalidCluster, invalidClusterIndex, array) => (
                        <span key={invalidClusterIndex} className="error_msg">
                          {`${invalidCluster}${invalidClusterIndex < array.length - 1 ? ' ,' : ''}`}
                        </span>
                      ))}
                  </div> */}
                </td>
                {errorMsg?.errors === undefined ? (
                  ''
                ) : (
                  <label className="row-warning">
                    {(errorMsg?.errors.length > 0 && errorMsg?.errors.some((item) => item.row)) ===
                    index
                      ? ''
                      : errorMsg?.errors.find((item) => item.row === index)?.error}
                  </label>
                )}
              </tr>
            ))}
          </tbody>

          <tfoot className="table_footer">
            <button
              type="button"
              className={`create-user-button ${isErrorDisplay ? 'disabled' : ''}`}
              onClick={sendClusterDetails}
              disabled={isErrorDisplay || isLoading}>
              {isLoading ? (
                <CircularProgress
                  size={18}
                  sx={{
                    color: '#262261',
                    margin: '0px 10px',
                  }}
                  color="secondary"
                />
              ) : (
                <span>Create Users</span>
              )}
            </button>
          </tfoot>
        </table>
      </div>

      <Modal isOpen={isModalOpen} style={customStyles} onRequestClose={closeModal}>
        <div className="modal_header">
          <h2>Error</h2>
          <span>
            <img src={Close} alt="close" onClick={closeModal} />
          </span>
        </div>
        <hr />
        <div className="modal_body">
          <span>
            <img src={caution} alt="caution" />
          </span>
          <p>Delete the error message rows and then create users.</p>
        </div>
        <div className="delete_container">
          <button className="modal_delete" onClick={confirmDeleteUser}>
            Okay
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AddNewUser;
