import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import './index.css';

import Profile from '../../assets/images/profile.svg';
import Cross from '../../assets/images/cross.svg';
import { updateCluster } from '../../services/cluster-service';
import { toast } from 'react-toastify';

const EditModal = (props) => {
  const [officers, setOfficers] = useState(props?.assingedFielOfficer);
  const [assingedManagers, setAssignedMangers] = useState(props?.assingedManager);
  const [formData, setFormData] = useState({
    toBeAdded: [],
    toBeDeleted: [],
  });
  const [managersOption, setManagersOption] = useState(props.managers);
  const [officersOption, setOfficersOption] = useState(props.fieldofficerOption);
  const [selectOfficer, setSelectOfficer] = useState([]);
  const [selectManager, setSelectManager] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  /**
   * useEffect hook that checks the validity of the form based on selected managers, officers, and users to be deleted.
   */
  useEffect(() => {
    const isManagerFieldValid = selectManager.length > 0;
    const isFieldOfficerFieldValid = selectOfficer.length > 0;
    const toBeDeletedUsers = formData.toBeDeleted.length > 0;
    setIsFormValid(isManagerFieldValid || isFieldOfficerFieldValid || toBeDeletedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * ^ Function to handle the change event for managers and officers, adding them to the form data.
   * @param {*} user - The user object.
   * @param {*} type - The type of user, either 'manager' or 'officer'.
   */

  const onChangeHandler = (user, type) => {
    if (type === 'manager') {
      let deletedManager = assingedManagers.filter((item) => item.user_id !== user.user_id);
      setManagersOption([
        ...managersOption,
        props.managers.find((obj) => obj.value === user.user_id),
      ]);
      setAssignedMangers(deletedManager);
      formData.toBeDeleted.push(user.user_id);
    } else {
      let officer = officers.filter((item) => item.user_id !== user.user_id);
      setOfficersOption([
        ...officersOption,
        props.fieldofficerOption.find((obj) => obj.value === user.user_id),
      ]);
      setOfficers(officer);
      formData.toBeDeleted.push(user.user_id);
    }
    const isManagerFieldValid = selectManager.length > 0;
    const isFieldOfficerFieldValid = selectOfficer.length > 0;
    setIsFormValid(isManagerFieldValid && isFieldOfficerFieldValid);
  };

  /**
   * useEffect hook to update managers and officers lists based on assigned managers and field officers.
   */
  useEffect(() => {
    //updating managers
    const userIDs = new Set(props.assingedManager.map((item) => item.user_id));
    setManagersOption(props.managers.filter((item) => !userIDs.has(item.value)));
    //updating field officers
    const officeruserIDs = new Set(props.assingedFielOfficer.map((item) => item.user_id));
    setOfficersOption(props.fieldofficerOption.filter((item) => !officeruserIDs.has(item.value)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *  Function to handle form submission, sending the updated cluster data to the backend.
   */
  const onSubmit = async () => {
    let payload = {
      cluster_id: props.data.id,
      users: {
        toBeAdded: [
          ...selectOfficer.map((item) => item.value),
          ...selectManager.map((item) => item.value),
        ],
        toBeDeleted: formData.toBeDeleted,
      },
    };

    const { status, error } = await updateCluster(payload);
    if (status === 200) {
      toast.success('Successfully Updated');
      props.setModalOpen({ ...props.modalOpen, staus: false });
    }
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-edit-user-modal-parent">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="view-edit-user-modal-body">
          <Form>
            <Form.Group
              className="view-edit-user-form-group view-edit-user-name cluster-wise-edit-fields d-flex flex-row my-2"
              key="name">
              <Form.Label className="view-edit-user-form-label">NAME OF CLUSTER</Form.Label>
              <Form.Control
                className="view-edit-user-input-name input-form-disabled  edit-cluster-name"
                name="name"
                type="text"
                value={props?.data?.name}
                placeholder="Enter your name"
                disabled
              />
            </Form.Group>
            <Form.Group
              className="view-edit-user-form-group view-edit-user-email cluster-wise-edit-fields d-flex flex-row my-2"
              key="email">
              <Form.Label className="view-edit-user-form-label">MANAGER</Form.Label>
              <Select
                classNamePrefix="react-select"
                name="manager"
                isMulti
                options={managersOption}
                value={selectManager}
                onChange={(e) => {
                  setSelectManager(e);
                }}
                placeholder="Search Manager"
              />
            </Form.Group>
            <div className="list-officers-container">
              <div className="list-wrapper">
                <label>Manager Assigned</label>
                <span>{assingedManagers?.length}</span>
              </div>
              <ul>
                {assingedManagers &&
                  assingedManagers?.length !== 0 &&
                  assingedManagers.map((item) => {
                    return (
                      <li className="officers-lists">
                        <div className="list-name-wrapper">
                          <label>
                            <img src={Profile} alt="profile" />
                          </label>
                          <span>{item.user_name}</span>
                        </div>
                        <label
                          className="image-container"
                          onClick={() => onChangeHandler(item, 'manager')}>
                          <img src={Cross} alt="cross" />
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <Form.Group
              className="view-edit-user-form-group cluster-wise-edit-fields d-flex flex-row my-2"
              key="field officer">
              <Form.Label className="view-edit-user-form-label">FIELD OFFICER</Form.Label>
              <Select
                classNamePrefix="react-select"
                name="fieldofficer"
                isMulti
                options={officersOption}
                value={selectOfficer}
                onChange={(e) => {
                  //onSelectHandler(e, "officer");
                  setSelectOfficer(e);
                }}
                placeholder="Search Field Officers"
              />
            </Form.Group>
            <div className="list-officers-container">
              <div className="list-wrapper">
                <label>Field Officers Assigned</label>
                <span>{officers?.length}</span>
              </div>
              <ul>
                {officers &&
                  officers.length !== 0 &&
                  officers.map((item) => {
                    return (
                      <li className="officers-lists">
                        <div className="list-name-wrapper">
                          <label>
                            <img src={Profile} alt="profile" />
                          </label>
                          <span>{item.user_name}</span>
                        </div>
                        <label
                          className="image-container"
                          onClick={() => onChangeHandler(item, 'officer')}>
                          <img src={Cross} alt="cross" />
                        </label>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="edit-user-save" onClick={() => onSubmit()} disabled={!isFormValid}>
            Save
          </Button>
          <Button onClick={props.onHide} className="edit-user-cancel">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
