import React, { useState, useEffect, useRef } from 'react';
import * as bootstrap from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Overlay from 'ol/Overlay';
import moment from 'moment';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { boundingExtent } from 'ol/extent';
import CircularProgress from '@mui/material/CircularProgress';
import * as constants from '../../utils/constants';
import { getCompanyId, getUserId, getUserGroupId } from '../../utils/localStorage';
import {
  sendSearchRequest,
  getPopupImage,
  sendGetUserForCluster,
  getAvailableDates,
} from '../../services/data-explorer-services';
import ActionModal from './actionModal';
import ApprovedRejectedModal from './approved-rejected-modal';
import FullPageLoader from '../../pages/fullpage-loader';
import Download from '../../pages/download-file/download';

import { listen, unlistenByKey } from 'ol/events';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Vector as VectorSource, Cluster } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import TextField from '@mui/material/TextField';

import 'ol/ol.css';
import { getCenter } from 'geolib';

// Components
import { SingleSelectPicker, MultiSelectPicker, DatePickers } from '../../components';

//Redux
import {
  getFormDetailsData,
  getFormClusterUserDetailsData,
} from '../../store/dataExplorer/data-explorer-action';
import { dataExplorerAction } from '../../store/dataExplorer/data-explorer-slice';

import './index.css';

// Images
import {
  downArrow,
  locationNormal,
  locationApprove,
  locationRejected,
  locationPending,
  openFormIcon,
} from '../../assets/images';
import arrow from '../../assets/images/arrow.svg';

const approveModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    padding: '0px',
  },
};
const confirmModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '333px',
    padding: '0px',
  },
};

const DownloadFilters = ({
  mapObject,
  setGtPointList,
  openConfirmation,
  setOpenConfirmation,
  modalPopUpStatus,
  setModalPopUpStatus,
  approveReject,
  rejectedReason,
  setIsApproveModalOpen,
  setSelectedDataForApproval,
  setSelectedFarmId,
  isApproveModalOpen,
  selectedDataForApproval,
}) => {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const { formData, clusterData, userData, formFieldsDetails } = useSelector(
    (state) => state.explorer
  );

  const [advancedFilterExpand, setAdvancedFilterExpand] = useState(true);
  const [searchSpinner, setSearchSpinner] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [clickListenerKey, setClickListenerKey] = useState(null);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [advancedFilterData, setAdvancedFilterData] = useState([]);
  const [selectOptions, setSelectOption] = useState({
    formOption: [],
    clusterOption: [],
    userOption: [],
    allClusterData: [],
    allUserData: [],
  });

  const [selectedInputs, setSelectedInputs] = useState({
    formId: '',
    startDate: null,
    endDate: null,
    clusterId: [],
    userId: [],
  });

  useEffect(() => {
    dispatch(getFormClusterUserDetailsData());
    setLoaderShow(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoaderShow(false);
    const formOption = formData.map((form) => ({
      value: form.form_id,
      label: form.form_name,
    }));

    const userOption = userData.map((user) => ({
      value: user.user_id,
      label: user.user_name,
    }));

    const clusterOption = clusterData.map((cluster) => ({
      value: cluster.cluster_id,
      label: cluster.cluster_name,
      geometry: cluster.geometry,
    }));

    setSelectOption({
      formOption,
      userOption,
      clusterOption,
      allUserData: userOption,
      allClusterData: clusterOption,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, clusterData, userData]);

  useEffect(() => {
    if (formFieldsDetails)
      setAdvancedFilterData(
        formFieldsDetails?.data?.form_fields
          ?.filter((item) => item.enable_filter)
          .reduce((acc, item) => {
            acc[item.key] = null;
            return acc;
          }, {})
      );
  }, [formFieldsDetails]);

  const onFormSelect = async (form) => {
    if (form) {
      clearMapLayers();
      drawClusterPolygon([]);
      setGtPointList([]);
      setSelectedInputs((prev) => ({
        ...prev,
        formId: form,
        userId: [],
        clusterId: [],
        startDate: null,
        endDate: null,
      }));

      dispatch(getFormDetailsData(form.value));
      if (form.value !== '') {
        const formId = form.value;
        const { data, status, error } = await getAvailableDates(formId);
        if (status === 200) {
          setHighlightedDates(data?.data?.available_date);
        }
        if (error) {
          toast.error(error.response?.data.message);
        }
      }
    } else {
      setSelectedInputs((prev) => ({
        ...prev,
        formId: '',
        userId: [],
        clusterId: [],
        startDate: null,
        endDate: null,
      }));
    }
  };

  const handleStartDateChange = (newDate) => {
    clearMapLayers();
    drawClusterPolygon([]);
    setGtPointList([]);
    setSelectedInputs((prev) => {
      return {
        ...prev,
        startDate: newDate,
        endDate: null,
        userId: [],
        clusterId: [],
      };
    });
  };

  const handleEndDateChange = (newDate) => {
    clearMapLayers();
    drawClusterPolygon([]);
    setGtPointList([]);
    setSelectedInputs((prev) => {
      return {
        ...prev,
        endDate: newDate,
        userId: [],
        clusterId: [],
      };
    });
  };

  const handleChangeClusterMultiSelect = (form) => {
    if (form) {
      drawClusterPolygon(form);
      clearMapLayers();
      setGtPointList([]);
      setSelectedInputs((prev) => {
        return {
          ...prev,
          clusterId: form,
        };
      });

      if (form.length !== 0) {
        getUserforCluster(form);
      } else {
        setSelectedInputs((prev) => ({
          ...prev,
          userId: [],
        }));
        setSelectOption((prev) => {
          return {
            ...prev,
            userOption: selectOptions.allUserData,
            clusterOption: selectOptions.allClusterData,
          };
        });
      }
    }
  };

  const getUserforCluster = async (user) => {
    const { data, status, error } = await sendGetUserForCluster(user);
    if (status === 200) {
      updateUserOption(data.data);
    } else if (status === 401) {
      toast.error(error?.response?.data?.message);
    } else {
      toast.error(error?.response?.data?.message);
    }
  };

  const updateUserOption = (newData) => {
    const newUserIds = newData.map((user) => user.id);
    const filteredSelectedUsers = selectedInputs.userId.filter((user) =>
      newUserIds.includes(user.value)
    );

    const userDataOptions = newData.map((user) => ({
      value: user.id,
      label: user.name,
    }));

    setSelectOption((prev) => ({
      ...prev,
      userOption: userDataOptions,
    }));

    setSelectedInputs((prev) => ({
      ...prev,
      userId: filteredSelectedUsers,
    }));
  };

  const handleChangeUserMultiSelect = (form) => {
    if (form) {
      clearMapLayers();
      setGtPointList([]);
      setSelectedInputs((prev) => {
        return {
          ...prev,
          userId: form,
        };
      });
    }
  };

  const onAdvanceFormSelect = (event, fieldDetails) => {
    setAdvancedFilterData((prevData) => ({
      ...prevData,
      [fieldDetails.key]: event,
    }));
  };

  const drawClusterPolygon = (form) => {
    const layersToRemove = [];
    mapObject.getLayers().forEach((layer) => {
      if (layer.get('name') === 'clusterPolygon') {
        layersToRemove.push(layer);
      }
    });

    layersToRemove.forEach((layer) => mapObject.removeLayer(layer));

    if (form.length === 0) {
      mapObject.getView().setCenter([8804131.622620502, 2409371.619506935]);
      mapObject.getView().setZoom(5);
      return;
    }

    const featureData = form.map((item) => {
      return {
        type: 'Feature',
        properties: {},
        geometry: JSON.parse(item.geometry),
        id: item.label,
      };
    });

    const vectorPolygonSource = new VectorSource({
      features: new GeoJSON().readFeatures(
        {
          type: 'FeatureCollection',
          features: featureData,
        },
        {
          dataProjection: 'EPSG:4326',
          featureProjection: mapObject.getView().getProjection(),
        }
      ),
    });

    const vectorLayer = new VectorLayer({
      source: vectorPolygonSource,
      style: new Style({
        fill: new Fill({ color: 'rgba(30, 224, 205, 0)' }),
        stroke: new Stroke({
          color: '#1EE0CD',
          width: 4,
        }),
      }),
    });

    vectorLayer.set('name', 'clusterPolygon');
    mapObject.addLayer(vectorLayer);

    const extent = vectorPolygonSource.getExtent();

    if (!isNaN(extent[0]) && !isNaN(extent[1]) && !isNaN(extent[2]) && !isNaN(extent[3])) {
      mapObject.getView().fit(extent, {
        duration: 1000,
        padding: [50, 50, 50, 50],
      });
    }
  };

  /**
   * Clears map layers and resets map view
   */
  const clearMapLayers = () => {
    mapObject.getLayers().forEach((layer) => {
      const layerName = layer?.get('name');
      if (['marker', 'markerPoint', 'markerPolygon'].includes(layerName)) {
        mapObject.removeLayer(layer);
      }
    });

    mapObject.getOverlays().forEach((overlay) => {
      const overlayName = overlay?.get('name');
      if (overlayName === 'overlay' || overlayName === 'markerPoint') {
        mapObject.removeOverlay(overlay);
      }
    });

    if (clickListenerKey) {
      unlistenByKey(clickListenerKey);
    }
  };

  const clearAll = () => {
    drawClusterPolygon([]);
    dispatch(dataExplorerAction.doClearDataExplorer());
    setGtPointList([]);
    setSelectedInputs(() => {
      return {
        formId: '',
        startDate: null,
        endDate: null,
        userId: [],
        clusterId: [],
      };
    });
    setSelectOption((prev) => {
      return {
        ...prev,
        clusterOption: selectOptions.allClusterData,
        userOption: selectOptions.allUserData,
      };
    });

    clearMapLayers();
  };

  const downloadCSVModal = () => {
    setDownloadModal(true);
  };

  /**
   * Function to hide the download modal.
   */
  const onHideDownloadModal = () => {
    setDownloadModal(false);
  };

  useEffect(() => {
    if (
      openConfirmation.reasonRejectionStatus === true ||
      openConfirmation.approveRejectStatus === true
    ) {
      onSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConfirmation]);

  const onSearch = async (isFitToExtent) => {
    setSearchSpinner(true);

    let payload = {
      form_id:
        typeof selectedInputs.formId === 'object' && selectedInputs.formId !== null
          ? selectedInputs.formId.value.toString()
          : selectedInputs.formId?.toString() || '',
      start_date: selectedInputs.startDate
        ? moment(selectedInputs.startDate, 'YYYY-MM-DD').format('YYYYMMDD')
        : '',
      end_date: selectedInputs.endDate
        ? moment(selectedInputs.endDate, 'YYYY-MM-DD').format('YYYYMMDD')
        : '',
      cluster_id:
        Array.isArray(selectedInputs.clusterId) && selectedInputs.clusterId.length > 0
          ? selectedInputs.clusterId.map((item) => item.value.toString())
          : [],
      user_id:
        Array.isArray(selectedInputs.userId) && selectedInputs.userId.length > 0
          ? selectedInputs.userId.map((item) => item.value.toString())
          : [],
      company_id: getCompanyId(),
      requesting_user: getUserId(),
      additional_filters: Object.fromEntries(
        Object.entries(advancedFilterData).filter(([key, value]) => value !== null)
      ),
    };
    const { data, status, error } = await sendSearchRequest(payload);
    if (status === 200) {
      setSearchSpinner(false);
      if (data?.data?.data.length > 0) {
        addNewLayer(data?.data, isFitToExtent);
        setGtPointList(data?.data?.data);
      } else {
        toast.info('No data found!!');
        setGtPointList([]);
      }
    } else if (status === 401) {
      setSearchSpinner(false);
      toast.error(error?.response?.data.message);
    } else {
      setSearchSpinner(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleClick = (event) => {
    const layer = mapObject
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'marker');

    const layerPoint = mapObject
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'markerPoint');
    pointPolygonPopup(layerPoint, event);

    const layerPolyon = mapObject
      .getLayers()
      .getArray()
      .find((layer) => layer.get('name') === 'markerPolygon');
    pointPolygonPopup(layerPolyon, event);

    layer.getFeatures(event.pixel).then(async (clickedFeatures) => {
      if (clickedFeatures?.length) {
        const features = clickedFeatures[0].get('features');
        if (features.length > 1) {
          const extent = boundingExtent(features.map((r) => r.getGeometry().getCoordinates()));
          mapObject.getView().fit(extent, { duration: 1000, padding: [100, 100, 100, 100] });
        }
      }
    });
  };

  const pointPolygonPopup = (layerPoint, event) => {
    const element = popupRef.current.element;

    let popover = bootstrap.Popover.getInstance(element);

    if (popover) {
      popover.dispose();
    }
    layerPoint.getFeatures(event.pixel).then(async (clickedFeatures) => {
      if (clickedFeatures?.length) {
        const { data, status } = await getPopupImage(clickedFeatures[0]?.values_?.polygon_id);

        if (status === 200) {
          setSelectedDataForApproval(data);
          setSelectedFarmId([data[0].user_farm_id]);
          var farmImages = data[0]?.imageURL.map((item, index) => {
            return {
              id: index + 1,
              image: item?.url,
            };
          });
          const { marked_by, date } = clickedFeatures[0].values_;
          const formattedDate = new Date(date).toLocaleDateString('en-IN', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          });
          const coordinate = event.coordinate;
          popupRef.current.setPosition(coordinate);

          let currentIndex = 0;
          if (farmImages.length > 3) {
            currentIndex = Math.floor((farmImages.length - 1) / 2) - 1;
          }
          const content = document.createElement('div');
          content.innerHTML = `
                                <div class="popover-content">
                                <div class="popover-content-action ${`${
                                  getUserGroupId(constants.USERGROUP_ID) === '1' ||
                                  getUserGroupId(constants.USERGROUP_ID) === '2'
                                    ? 'pop-up-gitlist'
                                    : ''
                                }`}">
                                    <img src=${openFormIcon} alt="open" id="form-open" />
                                </div>
                                  <div class="popover-content-header">
                                    <div class="popover-content-header-text-container">
                                   
                                      <div class="popover-content-title">Marked By</div>
                                      <div class="popover-content-detail">${marked_by}</div>
                                    </div>
                                    <div class="popover-content-header-text-container">
                                      <div class="popover-content-title">Date of Marking</div>
                                      <div class="popover-content-detail">${formattedDate}</div>
                                    </div>
                                    <div class="popover-content-header-text-container">
                                      <div class="popover-content-title">Crop Name</div>
                                      <div class="popover-content-detail">${
                                        data[0]?.crop_name || '-'
                                      }</div>
                                    </div>
                                  </div>
                                  <div class="popover-line"></div>
                                  <div class="popover-images-wrapper">
                                    ${
                                      farmImages.length > 3
                                        ? '<img src="' +
                                          arrow +
                                          '" alt="left" class="popover-left-arrow-image" />'
                                        : ''
                                    }
                                    <div class="popover-images-container"></div>
                                    ${
                                      farmImages.length > 3
                                        ? '<img src="' +
                                          arrow +
                                          '" alt="right" class="popover-right-arrow-image" />'
                                        : ''
                                    }
                                   
                                  </div>
                                </div>`;

          document.body.addEventListener('click', function (event) {
            if (event.target.id === 'form-open') {
              setIsApproveModalOpen(true);
              setModalPopUpStatus(clickedFeatures[0]?.values_?.status);
            }
          });

          const imagesContainer = content.querySelector('.popover-images-container');
          renderImages(imagesContainer, currentIndex);

          const popover = new bootstrap.Popover(element, {
            animation: true,
            container: element,
            content: content.innerHTML,
            html: true,
            placement: 'bottom',
            zIndex: 9999,
            sanitize: false,
          });

          popover.show();

          const farmImagesContainer = popover.tip.querySelector('.popover-images-container');
          const leftArrow = popover.tip.querySelector('.popover-left-arrow-image');
          const rightArrow = popover.tip.querySelector('.popover-right-arrow-image');

          if (farmImages.length !== 0) {
            farmImagesContainer.addEventListener('click', (e) => {
              const target = e.target;
              if (target.classList.contains('popover-farm-image-style')) {
                e.stopPropagation();
              }
            });
          }
          if (farmImages.length > 3) {
            leftArrow.addEventListener('click', (e) => {
              if (currentIndex !== 0) {
                updateIndex(-1);
              }
              e.stopPropagation();
            });

            rightArrow.addEventListener('click', (e) => {
              if (farmImages.length < 3 || currentIndex + 2 !== farmImages.length - 1) {
                updateIndex(1);
              }
              e.stopPropagation();
            });
          }

          function renderImages(container, index) {
            container.innerHTML = '';
            farmImages.slice(index, index + 3).forEach((item) => {
              const image = document.createElement('img');
              image.src = item.image;
              image.alt = 'farm';
              image.classList.add('popover-farm-image-style');
              container.appendChild(image);
            });
          }

          function updateIndex(change) {
            const newIndex = currentIndex + change;
            currentIndex = newIndex;
            const imagesContainer = popover.tip.querySelector('.popover-images-container');
            renderImages(imagesContainer, newIndex);
          }
        }
      }
    });
  };

  /**
   * ^ Function to add a new layer to the map.
   * @param {*} data - Data for the new layer
   * @param isFitToExtent - Fit the map to the extent of the combined sources
   */
  const addNewLayer = (data, isFitToExtent) => {
    clearMapLayers();

    const featureData = data.data.map((item) => ({
      ...item,
      properties: {
        ...item.properties,
        status: item.status,
      },
    }));

    const pointFeatures = featureData.map((feature) => {
      if (feature.geometry.type === 'Polygon') {
        const coordinates = feature.geometry.coordinates[0];
        const centroid = getCenter(coordinates);
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [centroid.longitude, centroid.latitude],
          },
          properties: feature.properties,
        };
      } else {
        return feature;
      }
    });

    const geojsonPointObject = {
      type: 'FeatureCollection',
      features: pointFeatures,
    };

    const vectorPointSource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonPointObject, {
        dataProjection: 'EPSG:4326',
        featureProjection: mapObject.getView().getProjection(),
      }),
    });

    const clusterPointSource = new Cluster({
      distance: 50,
      source: vectorPointSource,
    });

    const popup = new Overlay({
      element: document.getElementById('popup-ol'),
    });

    popup.set('name', 'overlay');
    popupRef.current = popup;
    mapObject.addOverlay(popup);

    const getClusterStyle = (size) =>
      new Style({
        image: new Circle({
          radius: 12,
          stroke: new Stroke({
            color: '#fff',
          }),
          fill: new Fill({
            color: '#FFDC25',
          }),
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({
            color: '#000',
          }),
        }),
      });

    const getIndividualStyle = (status) => {
      const iconMapping = {
        Approve: locationApprove,
        Rejected: locationRejected,
        Pending: locationPending,
        default: locationNormal,
      };

      const iconSrc = iconMapping[status] || iconMapping['default'];

      return new Style({
        image: new Icon({
          src: iconSrc,
          scale: 0.25,
        }),
      });
    };

    const vectorPointLayer = new VectorLayer({
      source: clusterPointSource,
      style: function (feature) {
        const size = feature.get('features').length;
        const status = size > 1 ? '' : feature.get('features')[0]?.get('status');

        return size > 1 ? getClusterStyle(size) : getIndividualStyle(status);
      },
    });

    vectorPointLayer.set('name', 'marker');
    mapObject.addLayer(vectorPointLayer);

    const handleClickId = listen(mapObject, 'click', handleClick);
    setClickListenerKey(handleClickId);

    const vectorPoint = new VectorSource({
      features: new GeoJSON().readFeatures(
        { type: 'FeatureCollection', features: featureData },
        {
          dataProjection: 'EPSG:4326',
          featureProjection: mapObject.getView().getProjection(),
        }
      ),
    });

    const vectorHmLayer = new VectorLayer({
      source: vectorPoint,
      style: (feature) => getIndividualStyle(feature.get('status')),
    });

    vectorHmLayer.set('name', 'markerPoint');
    mapObject.addLayer(vectorHmLayer);

    const vectorPolygonSource = new VectorSource({
      features: new GeoJSON().readFeatures(
        { type: 'FeatureCollection', features: featureData },
        {
          dataProjection: 'EPSG:4326',
          featureProjection: mapObject.getView().getProjection(),
        }
      ),
    });

    const vectorPolygonLayer = new VectorLayer({
      source: vectorPolygonSource,
      style: function (feature) {
        const status = feature.get('status');
        const commonStrokeStyle = {
          width: 2,
          zIndex: 999,
        };

        let defaultStyle = new Style({
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, 1)',
            ...commonStrokeStyle,
          }),
          fill: new Fill({
            color: 'rgba(30, 224, 205, 50%)',
          }),
        });

        let statusStyles = {
          Approve: new Style({
            stroke: new Stroke({
              color: 'rgba(39,174,96)',
              ...commonStrokeStyle,
            }),
            fill: new Fill({
              color: 'rgba(39,174,96,0.50)',
            }),
          }),
          Rejected: new Style({
            stroke: new Stroke({
              color: 'rgba(235,87,87)',
              ...commonStrokeStyle,
            }),
            fill: new Fill({
              color: 'rgba(235,87,87,0.50)',
            }),
          }),
          Pending: new Style({
            stroke: new Stroke({
              color: 'rgba(242,153,74)',
              ...commonStrokeStyle,
            }),
            fill: new Fill({
              color: 'rgba(242,153,74,0.50)',
            }),
          }),
        };

        if (statusStyles[status]) {
          return statusStyles[status];
        } else {
          return defaultStyle;
        }
      },
    });

    vectorPolygonLayer.set('name', 'markerPolygon');
    mapObject.addLayer(vectorPolygonLayer);

    vectorPolygonLayer.setVisible(!isFitToExtent);
    vectorHmLayer.setVisible(!isFitToExtent);
    vectorPointLayer.setVisible(isFitToExtent);

    //Update the layer visibility based on zoom level
    mapObject.getView().on('change:resolution', function () {
      const currentZoom = mapObject.getView().getZoom();
      if (currentZoom >= 17.5) {
        vectorPointLayer.setVisible(false);
        vectorPolygonLayer.setVisible(true);
        vectorHmLayer.setVisible(true);
      } else {
        vectorPointLayer.setVisible(true);
        vectorPolygonLayer.setVisible(false);
        vectorHmLayer.setVisible(false);
      }
    });

    if (isFitToExtent) {
      // Fit the map to the extent of the combined sources
      const extent = clusterPointSource.getSource().getExtent();
      // .concat(vectorPolygonSource.getSource().getExtent());
      mapObject.getView().fit(extent, {
        padding: [100, 100, 100, 100],
        duration: 1000,
        // maxZoom: 5
      });
    }
  };

  return (
    <>
      <div className="data_filters_container col-3">
        <div className="heading_sections d-flex flex-row justify-content-between">
          <span>Filters</span>
          <span onClick={() => clearAll()}>clear all</span>
        </div>
        <div className="data-explorer-filter">
          <ul className="filter_list">
            <li>
              <SingleSelectPicker
                options={selectOptions?.formOption}
                label="Select Form*"
                handleChange={onFormSelect}
                disabled={false}
                value={selectedInputs.formId}
              />
            </li>
            <li>
              <DatePickers
                availableDates={highlightedDates}
                selectedDate={selectedInputs.startDate}
                title="Start Date*"
                handleDateChange={handleStartDateChange}
                disabled={!selectedInputs?.formId}
              />
            </li>
            <li>
              <DatePickers
                availableDates={highlightedDates}
                selectedDate={selectedInputs.endDate}
                title="End Date*"
                handleDateChange={handleEndDateChange}
                disabled={!(selectedInputs.startDate && selectedInputs.formId)}
              />
            </li>
            {getUserGroupId(constants.USERGROUP_ID) === '1' ||
            getUserGroupId(constants.USERGROUP_ID) === '2' ? (
              <>
                <MultiSelectPicker
                  className="select_cluster"
                  options={selectOptions.clusterOption || []}
                  label="Select Cluster"
                  handleChangeMultiSelect={handleChangeClusterMultiSelect}
                  disabled={!(selectedInputs.startDate && selectedInputs.endDate)}
                  value={selectedInputs.clusterId}
                />
                <MultiSelectPicker
                  className="select_user"
                  options={selectOptions.userOption || []}
                  label="Select User"
                  handleChangeMultiSelect={handleChangeUserMultiSelect}
                  disabled={!(selectedInputs.startDate && selectedInputs.endDate)}
                  value={selectedInputs.userId}
                />
                <h1 className="mandatory_field">*Mandatory Field</h1>
              </>
            ) : null}
          </ul>

          {(getUserGroupId(constants.USERGROUP_ID) === '1' ||
            getUserGroupId(constants.USERGROUP_ID) === '2') &&
          formFieldsDetails.length !== 0 &&
          advancedFilterData &&
          Object.keys(advancedFilterData).length !== 0 ? (
            <>
              <div className="additional_header">
                <label>Additional Filters</label>
                <span
                  className={`${advancedFilterExpand ? 'arrow_down' : 'arrow_right'}`}
                  onClick={() => {
                    setAdvancedFilterExpand(!advancedFilterExpand);
                  }}>
                  <img src={downArrow} alt="arrow" />
                </span>
              </div>
              <ul className={`filter_list ${!advancedFilterExpand && 'filter_hide'}`}>
                {formFieldsDetails?.data?.form_fields
                  .filter((fieldDetails) => fieldDetails.enable_filter)
                  .map((fieldDetails) => {
                    const { key, metadata } = fieldDetails;
                    const { input_type, label, data } = metadata;

                    const handleSelectChange = (event) => {
                      if (event?.value) {
                        onAdvanceFormSelect(event.value, fieldDetails);
                      } else {
                        setAdvancedFilterData((prevData) => ({
                          ...prevData,
                          [key]: event,
                        }));
                      }
                    };

                    switch (input_type) {
                      case 'dropdown': {
                        const formatOptions = Array.isArray(data)
                          ? data.map((field) => ({ value: field, label: field }))
                          : [];

                        return (
                          <li key={key} className="explorer-form-select">
                            <SingleSelectPicker
                              open={true}
                              options={formatOptions}
                              label={label}
                              handleChange={handleSelectChange}
                              disabled={false}
                              value={
                                advancedFilterData[key]
                                  ? {
                                      value: advancedFilterData[key],
                                      label: advancedFilterData[key],
                                    }
                                  : null
                              }
                            />
                          </li>
                        );
                      }

                      case 'text_box':
                        return (
                          <li key={key} className="explorer-form-select">
                            <TextField
                              sx={{ width: '100%' }}
                              id="outlined-basic"
                              label={label}
                              variant="outlined"
                              value={advancedFilterData[key] || ''}
                              onChange={(event) =>
                                onAdvanceFormSelect(event.target.value, fieldDetails)
                              }
                            />
                          </li>
                        );

                      case 'date_picker':
                        return (
                          <li key={key} className="explorer-form-select">
                            <DatePickers
                              availableDates={[]}
                              selectedDate={
                                advancedFilterData[key] &&
                                moment(advancedFilterData[key], 'DD MMMM YYYY').toDate()
                              }
                              title={label}
                              handleDateChange={(date) =>
                                onAdvanceFormSelect(
                                  moment(date).format('DD MMMM YYYY'),
                                  fieldDetails
                                )
                              }
                              disabled={false}
                            />
                          </li>
                        );

                      default:
                        return null;
                    }
                  })}
              </ul>
            </>
          ) : null}
        </div>
        <div className="filter_list">
          <button
            type="button"
            onClick={() => onSearch(true)}
            disabled={!(selectedInputs.startDate && selectedInputs.endDate)}
            className={`btn explorer-search-button ${
              selectedInputs.startDate === null &&
              selectedInputs.endDate === null &&
              'data-explorer-button-disabled'
            }`}>
            <strong>Search</strong>
            {searchSpinner && (
              <CircularProgress
                size={18}
                sx={{
                  color: '#262261',
                  margin: '0px 10px',
                }}
                color="secondary"
              />
            )}
          </button>
          <button
            type="button"
            onClick={() => {
              downloadCSVModal();
            }}
            disabled={!(selectedInputs.startDate && selectedInputs.endDate)}
            className={`btn explorer-download-button ${
              selectedInputs.startDate === null &&
              selectedInputs.endDate === null &&
              'data-explorer-button-disabled'
            }
           `}>
            <strong>Download</strong>
          </button>
        </div>
      </div>

      <Download
        downloadFilterData={selectedInputs}
        additionalFilters={advancedFilterData}
        onHideDownloadModal={() => {
          onHideDownloadModal();
        }}
        showDownloadModal={downloadModal}
      />

      <FullPageLoader loaderShow={loaderShow} />

      <Modal
        isOpen={isApproveModalOpen}
        style={approveModalStyles}
        onRequestClose={() => {
          setIsApproveModalOpen(false);
        }}
        ariaHideApp={false}
        contentLabel="Approve/Reject Modal">
        <ActionModal
          modalPopUpStatus={modalPopUpStatus}
          setIsApproveModalOpen={setIsApproveModalOpen}
          openConfirmation={openConfirmation}
          setOpenConfirmation={setOpenConfirmation}
          approveReject={approveReject}
          selectedDataForApproval={selectedDataForApproval}
        />
      </Modal>

      <ApprovedRejectedModal
        openConfirmation={openConfirmation}
        setOpenConfirmation={setOpenConfirmation}
        confirmModalStyles={confirmModalStyles}
        rejectedReason={rejectedReason}
      />
    </>
  );
};

export default DownloadFilters;
