import * as React from 'react';
import Fuse from 'fuse.js';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';

import { GET_USER_CLUSTER_DATA, GET_CLUSTERS } from '../../utils/url-helpers';
import { getRequest } from '../../utils/http-helper';
import * as constants from '../../utils/constants';
import { getUserId } from '../../utils/localStorage';
import info_icon from '../../assets/images/Info_circle.svg';

import './user-management.css';

import ViewEditUser from '../view-edit-user';
import FullPageLoader from '../../pages/fullpage-loader/index';

const headCells = [
  {
    id: 'srno.',
    numeric: true,
    disablePadding: false,
    label: 'Sr No.',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name of User',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Email Id',
  },
  {
    id: 'phonenumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'noOfCluster',
    numeric: true,
    disablePadding: false,
    label: 'No of Cluster',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f1f1f1',
    color: '#262261',
    fontSize: '18px',
    padding: '10px',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '10px',
    textTransform: 'capitalize',
  },
}));

const UserTableHead = (props) => {
  const { order, orderBy } = props;

  return (
    <TableHead className="user-table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

UserTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'black',
    maxWidth: 250,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '12',
    border: '1px solid #dadde9',
    position: 'absolute',
  },
}));

export default function UserTable({ searchText }) {
  let navigate = useNavigate();
  const [loaderShow, setLoaderShow] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [userData, setUserData] = React.useState([]);
  const [allUserData, setAllUserData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [viewEditData, setViewEditData] = React.useState({
    viewEditUserModalShow: false,
    viewEditUserData: {
      allClusterData: [],
      id: '',
      name: '',
      email: '',
      phone: '',
      role: '',
      selectedClusterData: [],
      fieldOfficers: [],
    },
    selectedUser: '',
    isFormDisabled: true,
  });
  const roleOptions = [
    { value: 0, label: 'Select Role' },
    { value: 1, label: 'Admin' },
    { value: 2, label: 'Manager' },
    { value: 3, label: 'Field Officers' },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getUserClusterData(getUserId());
    getClusterforUser(getUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function to initiate the retrieval of user cluster data.
   */
  const callUsermanagement = () => {
    getUserClusterData(getUserId());
    getClusterforUser(getUserId());
  };

  /**
   * ^ Function to send a request to obtain user cluster data.
   * @param {*} user - The user for whom the cluster data is requested.
   * @returns - The response containing the user's cluster data.
   */
  const sendGetUserClusterData = async (user) => {
    try {
      return await getRequest({
        url: GET_USER_CLUSTER_DATA.replace('user_id', user),
        authHeader: true,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  /**
   * & Function to retrieve user cluster data and update the relevant state variables.
   * @param {*} user - The user for whom the cluster data is retrieved.
   */

  const getUserClusterData = async (user) => {
    setLoaderShow(true);
    const { data, status } = await sendGetUserClusterData(user);
    if (status === 200) {
      setLoaderShow(false);
      let value = data?.data.userwise_clusters ? data?.data.userwise_clusters : [];
      setUserData(value);
      setAllUserData(value);
    } else if (status === 401) {
      navigate('/login');
      toast.error(data.msg);
    } else {
      setLoaderShow(false);
      toast.error(data.msg);
    }
  };

  /**
   * ^ Function to open the view user modal for a specific user.
   * @param {*} id - The ID of the user for whom the modal is being opened.
   */
  const openViewUserModal = (id) => {
    const viewData = userData.find((element) => element.id === id);
    setViewEditData((prev) => {
      return {
        ...prev,
        viewEditUserModalShow: true,
        selectedUser: id,
        viewEditUserData: {
          ...prev['viewEditUserData'],
          selectedClusterData: setSelectedClusters(viewData.clusters),
          id: viewData.id,
          name: viewData.name,
          email: viewData.email,
          phone: viewData.mobile,
          role: roleOptions.find(({ value }) => value === viewData.role),
        },
      };
    });
  };

  /**
   * & Function to send a request to obtain clusters associated with a user.
   * @param {*} user - The user for whom the clusters are requested.
   * @returns - The response containing the clusters associated with the user.
   */
  const sendGetClusterForUser = async (user) => {
    try {
      return await getRequest({
        url: GET_CLUSTERS.replace('user_id', user),
        authHeader: true,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  /**
   * ^ Function to retrieve clusters associated with a user and update the cluster options.
   * @param {*} user - The user for whom the clusters are being retrieved.
   */
  const getClusterforUser = async (user) => {
    const { data, status } = await sendGetClusterForUser(user);
    if (status === 200) {
      updateClusterOption(data.data);
    } else if (status === 401) {
      navigate('/login');
      toast.error(data?.msg || 'Unauthorized');
    } else {
      toast.error(data?.msg || 'An error occurred');
    }
  };

  /**
   * & Function to update the cluster options based on the received data.
   * @param {*} data - The data containing the clusters to be updated.
   */
  const updateClusterOption = (data) => {
    let clusterData = [{ value: 0, label: 'Select Cluster' }];
    data.forEach((cluster) => {
      clusterData.push({
        value: cluster.cluster_id,
        label: cluster.cluster_name,
      });
    });
    setViewEditData((prev) => {
      return {
        ...prev,
        viewEditUserData: {
          ...prev['viewEditUserData'],
          allClusterData: clusterData,
        },
      };
    });
  };

  /**
   * ^ Function to set the selected clusters for a user.
   * @param {*} data - The data containing the selected clusters to be set.
   * @returns - The selected clusters data.
   */
  const setSelectedClusters = (data) => {
    let clusterData = [];
    data.forEach((cluster) => {
      clusterData.push({
        value: cluster.cluster_id,
        label: cluster.cluster_name,
      });
    });
    return clusterData;
  };

  const onModalHide = () => {
    setViewEditData((prev) => {
      return {
        ...prev,
        viewEditUserModalShow: false,
        isFormDisabled: true,
      };
    });
    getClusterforUser(getUserId());
  };

  useEffect(() => {
    getUserClusterData(getUserId());
    getClusterforUser(getUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchText !== '') {
      const options = {
        keys: ['mobile', 'email', 'name'],
      };
      const fuse = new Fuse(allUserData, options);
      const result = fuse.search(searchText);
      setUserData(result.map((item) => item.item));
    } else {
      setUserData(allUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const getTableData = (data) => {
    return searchText === ''
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data.slice(0, 20);
  };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer className="user-table">
            <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
              <UserTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={userData.length}
              />
              <TableBody>
                {getTableData(userData).map((row, index) => {
                  return (
                    <TableRow
                      tabIndex={-1}
                      // key={row.name}
                    >
                      <StyledTableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell>
                      <StyledTableCell align="left" style={{ textTransform: 'lowercase' }}>
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.mobile}</StyledTableCell>
                      <StyledTableCell align="left">
                        {constants.USER_TYPE[row.role]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.clusters.length ? row.clusters.length : '-'}
                        {row.clusters.length ? (
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <div className="user-cluster-list">
                                  {row.clusters.map((arrayData, idx) => {
                                    return (
                                      <span className="user-cluster-names">
                                        {arrayData.cluster_name}
                                      </span>
                                    );
                                  })}
                                </div>
                              </React.Fragment>
                            }>
                            <img src={info_icon} alt="info-icon" />
                          </HtmlTooltip>
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <span className="user-view-link" onClick={() => openViewUserModal(row.id)}>
                          view
                        </span>
                        {/* <span className="user-view-link">suspend</span> */}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
                {userData.length === 0 && (
                  <TableRow>
                    <TableCell align="center" colSpan={12}>
                      No record available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {searchText === '' ? (
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={allUserData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ backgroundColor: '#f1f1f1' }}
              labelDisplayedRows={(page) =>
                `Showing ${page.from}-${page.to === -1 ? page.count : page.to} of  ${page.count}`
              }
            />
          ) : null}
        </Paper>
      </Box>
      <ViewEditUser
        show={viewEditData.viewEditUserModalShow}
        data={viewEditData}
        setViewEditData={setViewEditData}
        callUsermanagement={() => {
          callUsermanagement();
        }}
        onHide={() => onModalHide()}
      />
      <FullPageLoader loaderShow={loaderShow} />
    </>
  );
}
