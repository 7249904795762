import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { SettingsHeader } from '../settings-components';
import profile_logo from '../../assets/images/user_icon.svg';
import { updateUserDetails } from '../../services/auth-services';
import { toast } from 'react-toastify';
import { getRequest } from '../../utils/http-helper';
import { GET_USER_DETAILS } from '../../utils/url-helpers';

import * as constants from '../../utils/constants';
import { getUserGroupId } from '../../utils/localStorage';

import './profile-settings.css';

export default function ProfileSettings() {
  const [credentials, setCredentials] = useState({
    name: '',
    email: '',
  });
  const [btnStatus, setBtnStatus] = useState(true);

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendGetProfileDetails = async () => {
    try {
      return await getRequest({
        url: GET_USER_DETAILS,
        authHeader: true,
      });
    } catch (error) {
      return error;
    }
  };
  const getProfileDetails = async () => {
    const { data, status } = await sendGetProfileDetails();
    if (status === 200) {
      setCredentials({
        ...credentials,
        name: data.data.name,
        email: data.data.email,
        mobile: data.data.mobile,
        organization: data.data.company,
      });
    }
  };
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setBtnStatus(false);
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name: credentials.name,
      email: credentials.email,
    };
    const { status, error } = await updateUserDetails(payload);
    if (status === 200) {
      getProfileDetails();
      toast.success('User Profile Successfully Updated');
    } else if (status === 401) {
      toast.error(error?.response?.data?.message);
    }
  };

  const renderInput = (label, name) => (
    <div className="profile-details-wrapper-item">
      <span className="profile-details-wrapper-label">{label}</span>
      <div className="profile-details-wrapper-input-container">
        <input
          value={credentials[name]}
          type={'text'}
          className="form-control p-2 profile-details-input"
          name={name}
          id={name}
          disabled={label === 'Mobile' || label === 'Organization'}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );

  return (
    <div className="profile-settings-container">
      <div className="profile-settings-sub-container">
        <SettingsHeader title="Change your profile picture" />

        <section className="profile-settings-section">
          <div className="profile-image-container">
            <img className="profile-image" src={profile_logo} alt="profile-logo" />
          </div>
          <span className="profile-name-text">{credentials.name}</span>
        </section>
      </div>

      <div className="profile-settings-sub-container">
        <SettingsHeader title="Change your details" />

        <section className="profile-settings-section">
          <div>
            {['Name', 'Email', 'Mobile'].map((label) =>
              renderInput(label, label.toLowerCase().split(' ').join(''))
            )}
          </div>

          {getUserGroupId(constants.USERGROUP_ID) !== '4' && (
            <div className="profile-details-wrapper-item">
              <span className="profile-details-wrapper-label">Organization</span>
              <div className="profile-details-wrapper-input-container">
                <input
                  value={credentials.organization}
                  type={'text'}
                  className="form-control p-2 profile-details-input"
                  disabled={true}
                />
              </div>
            </div>
          )}
          <div className="save-password-button-container">
            <Button
              variant="contained"
              disabled={btnStatus}
              onClick={handleSubmit}
              className="save-button">
              Save
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
}
